import { TransactionUrlMapper } from '@binhatch/ui';
import { TransactionSource } from 'flexinet-api';

import { urls } from '@/utils/url';

export const transactionUrlMapper: TransactionUrlMapper = {
  [TransactionSource.SalesEvent]: (promotionId) => urls.promotions.getOne({ promotionId }),
  [TransactionSource.Promotion]: (promotionId) => urls.promotions.getOne({ promotionId }),
  [TransactionSource.Webshop]: (orderId) => urls.orders.getOne({ orderId })
};
