import { useRemoteData } from '@binhatch/hooks';
import { createContainer } from 'unstated-next';

import { TENANT } from '@/config';
import { tenantApi } from '@/integrations/api';

const useTenant = () => {
  return useRemoteData({ key: 'useTenant', tenant: TENANT }, async ({ tenant }) =>
    tenantApi.listTenants(tenant).then((r) => r.data)
  );
};

export const Tenant = createContainer(useTenant);
