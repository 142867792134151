import { autoUpdate, flip, offset, size, useFloating } from '@floating-ui/react-dom';
import { Transition } from '@headlessui/react';
import { ChevronUpDownIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';
import { UseComboboxProps, UseComboboxState, UseComboboxStateChangeOptions, useCombobox } from 'downshift';
import isEqual from 'lodash/isEqual';
import React from 'react';

import { Input } from '../Input';

import { SelectItem, SelectItemProps } from './SelectItem';

export interface Item<T> {
  value: T;
  name: string;
  disabled?: boolean;
}

export interface RenderItemsProps<I> {
  items: I[];
  inputValue?: string;
  children: React.ReactNode;
}

export interface RenderItemProps<I> {
  props: any;
  item: I;
}

export interface SelectProps<T, I = Item<T>>
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'value'> {
  value?: T | T[] | null;
  items: I[];
  inputValue?: string;
  multiple?: boolean;
  invalid?: boolean;
  getValue?(item: I): T;
  getDisplayName?(item: I): string;
  getInputValue?(isOpen: boolean, inputValue: string, displayName: string): string;
  getSelectedDisplayName?(props: { inputValue: string; selectedItems: I[] }): string;
  selectItem?: React.ComponentType<SelectItemProps>;
  className?: string;
  containerClassName?: string;
  contentClassName?: string;
  dropdownClassName?: string;
  itemsClassName?: string;
  placementStrategy?: 'absolute' | 'fixed';
  editable?: boolean;
  onInputValueChange?(inputValue: string): any;
  onChange?(value: T[] | T | null): any;
  stateReducer?(
    state: UseComboboxState<I>,
    { type, changes }: UseComboboxStateChangeOptions<I>
  ): Partial<UseComboboxState<I>>;
}

interface SelectItemPropsWithExtras<I> extends SelectItemProps {
  item: I;
  getDisplayName?(item: I): string;
}

const DefaultSelectItem = React.forwardRef(
  <I extends any>({ getDisplayName, item, ...props }: SelectItemPropsWithExtras<I>, ref: React.Ref<HTMLLIElement>) => (
    <SelectItem {...{ ref }} {...props}>
      {getDisplayName?.(item)}
    </SelectItem>
  )
);

export const Select = React.forwardRef(
  <T, I extends Item<any>>(props: React.PropsWithChildren<SelectProps<T, I>>, ref: React.Ref<HTMLInputElement>) => {
    const {
      getValue = (item) => (item ? item.value : null),
      getDisplayName = (item) => (item ? item.name : ''),
      getSelectedDisplayName = ({ selectedItems }) => selectedItems.map(getDisplayName).join(', ') || '',
      getInputValue = (isOpen, inputValue, displayName) => (isOpen ? inputValue || '' : displayName),
      selectItem: Item = DefaultSelectItem,
      stateReducer: propStateReducer = (_, { changes }) => changes,
      placementStrategy = 'absolute',
      editable = false
    } = props;

    const selectedValues = React.useMemo(() => {
      // if (props.value == null) return [];
      if (Array.isArray(props.value)) return props.value;

      return [props.value];
    }, [props.value]);

    const selectedItems = React.useMemo(
      () =>
        selectedValues
          .map((value) => props.items.find((item) => isEqual(getValue(item), value))!)
          .filter((item) => item),
      [selectedValues, props.items, getValue]
    );

    const stateReducer = React.useCallback(
      (
        state: UseComboboxState<I>,
        { type, changes }: UseComboboxStateChangeOptions<I>
      ): Partial<UseComboboxState<I>> => {
        switch (type) {
          case useCombobox.stateChangeTypes.FunctionOpenMenu: {
            if (props.multiple) return propStateReducer(state, { type, changes });

            return propStateReducer(state, {
              type,
              changes: {
                ...changes,
                highlightedIndex:
                  selectedValues.length > 0
                    ? props.items.findIndex((item) => isEqual(getValue(item), selectedValues[0]))!
                    : -1
              }
            });
          }
          case useCombobox.stateChangeTypes.InputKeyDownEnter:
          case useCombobox.stateChangeTypes.ItemClick:
            return propStateReducer(state, {
              type,
              changes: {
                ...changes,
                isOpen: !!props.multiple,
                highlightedIndex: state.highlightedIndex,
                inputValue: '',
                selectedItem: changes.selectedItem
              }
            });
          case useCombobox.stateChangeTypes.InputBlur:
            return propStateReducer(state, { type, changes: { ...changes, inputValue: '' } });
          case useCombobox.stateChangeTypes.InputChange:
            return propStateReducer(state, { type, changes: { ...changes, highlightedIndex: 0 } });
          case useCombobox.stateChangeTypes.InputClick:
            return propStateReducer(state, { type, changes: { ...changes, isOpen: true } });
          default:
            return propStateReducer(state, { type, changes });
        }
      },
      [selectedValues, props.multiple, props.items, propStateReducer, getValue]
    );

    const removeValue = React.useCallback(
      (selectedValue: T) => props.onChange?.(selectedValues.filter((value) => value !== selectedValue) as any),
      [selectedValues, props]
    );

    const addValue = React.useCallback(
      (selectedValue: T) => props.onChange?.([...selectedValues, selectedValue] as any),
      [selectedValues, props]
    );

    const updateValues = React.useCallback(
      (selectedValues: T | T[] | null) => props.onChange?.(selectedValues),
      [props]
    );

    const onSelectedItemChange = React.useCallback(
      ({ selectedItem }: Parameters<NonNullable<UseComboboxProps<I>['onSelectedItemChange']>>[0]) => {
        if (!selectedItem) return;

        const selectedValue = getValue(selectedItem);

        if (!props.multiple) return updateValues(selectedValue);

        if (selectedValues.some((value) => isEqual(value, selectedValue))) {
          return removeValue(selectedValue);
        }

        return addValue(selectedValue);
      },
      [getValue, updateValues, selectedValues, removeValue, addValue, props.multiple]
    );

    const onInputValueChange = React.useCallback(
      ({ inputValue }: Parameters<NonNullable<UseComboboxProps<I>['onInputValueChange']>>[0]) =>
        props.onInputValueChange?.(inputValue!),
      [props]
    );

    const input = React.useRef<HTMLInputElement>();

    React.useImperativeHandle(ref, () => input.current!, []);

    const { isOpen, inputValue, getMenuProps, getToggleButtonProps, highlightedIndex, getItemProps, getInputProps } =
      useCombobox({
        id: props.id,
        inputId: props.id,
        inputValue: props.inputValue,
        itemToString: (item) => (item ? item.name : ''),
        items: props.items,
        stateReducer,
        onInputValueChange,
        onSelectedItemChange,
        selectedItem: null
      });

    const value = React.useMemo(() => {
      return getInputValue(
        isOpen,
        inputValue,
        getSelectedDisplayName({
          selectedItems,
          inputValue: inputValue || ''
        })
      );
    }, [getInputValue, isOpen, inputValue, getSelectedDisplayName, selectedItems]);

    const floating = useFloating({
      placement: 'bottom-start',
      strategy: placementStrategy,
      middleware: [
        offset(4),
        flip(),
        size({
          apply({ rects, elements }) {
            Object.assign(elements.floating.style, { minWidth: `${rects.reference.width}px` });
          }
        })
      ]
    });

    React.useEffect(() => {
      if (floating.refs.reference.current && floating.refs.floating.current) {
        return autoUpdate(floating.refs.reference.current, floating.refs.floating.current, floating.update);
      }
    }, [isOpen, floating.update, floating.refs.reference, floating.refs.floating]);

    return (
      <div className={classNames('relative', props.containerClassName)}>
        <div
          className={classNames('relative h-full w-full cursor-default text-left', props.contentClassName)}
          ref={floating.refs.setReference}
        >
          <Input
            {...getInputProps({
              type: 'text',
              placeholder: props.placeholder,
              value,
              readOnly: !editable || props.readOnly,
              disabled: props.disabled || props.readOnly,
              className: classNames(
                'w-full pr-8',
                props.readOnly ? 'cursor-default' : !editable ? 'cursor-pointer' : undefined,
                props.className
              ),
              ref: input as any,
              autoFocus: props.autoFocus,
              onFocus: props.onFocus,
              onBlur: props.onBlur
            })}
            invalid={props.invalid}
          />

          <button
            {...getToggleButtonProps({
              type: 'button',
              disabled: props.disabled || props.readOnly,
              className: 'absolute inset-y-0 right-0 flex items-center pr-2'
            })}
          >
            <ChevronUpDownIcon aria-hidden="true" className="h-6 w-6 text-[#003F2E4D]" />
          </button>
        </div>

        <div
          className="z-[1]"
          ref={floating.refs.setFloating}
          style={{
            position: floating.strategy,
            top: floating.y ?? undefined,
            left: floating.x ?? undefined
          }}
        >
          <Transition
            as={React.Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            show={isOpen}
          >
            <SelectMenu {...getMenuProps({}, { suppressRefError: true })}>
              {props.items.map((item, index) => (
                <Item
                  key={index}
                  active={highlightedIndex === index}
                  isSelected={
                    !!item && selectedItems.some((selectedItem) => isEqual(getValue(selectedItem), getValue(item)))
                  }
                  disabled={item.disabled}
                  {...getItemProps({ item, index })}
                  {...{ item, getDisplayName }}
                />
              ))}
            </SelectMenu>
          </Transition>
        </div>
      </div>
    );
  }
);

const SelectMenu = React.forwardRef<HTMLUListElement, React.PropsWithChildren>(({ children, ...props }, ref) => (
  <ul
    {...{ ref }}
    {...props}
    className={classNames({
      'max-h-60 overflow-y-auto rounded border border-solid border-[#003F2E30] bg-white py-1 shadow-lg':
        React.Children.count(children)
    })}
  >
    {children}
  </ul>
));
