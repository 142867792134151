import { fuzzySearch } from '@binhatch/search';
import React from 'react';

import { Item, Select, SelectProps } from './Select';

const defaultGetSearchableAttributes = (item: Item<unknown>) => [item.name];

interface SearchableSelectProps<T, I> extends Omit<SelectProps<T, I>, 'inputValue' | 'onInputValueChange'> {
  getSearchableAttributes?(item: I): string[];
}

export const SearchableSelect = React.forwardRef(
  <T, I extends Item<any>>(
    { getSearchableAttributes, ...props }: SearchableSelectProps<T, I>,
    ref: React.Ref<HTMLInputElement>
  ) => {
    const [inputValue, onInputValueChange] = React.useState('');

    const items = React.useMemo(() => {
      if (!inputValue.trim().length) return props.items;

      return fuzzySearch({
        term: inputValue,
        items: props.items,
        getSearchableAttributes: getSearchableAttributes ?? defaultGetSearchableAttributes,
        threshold: 0.8
      });
    }, [inputValue, props.items, getSearchableAttributes]);

    return <Select {...{ ref, inputValue, onInputValueChange }} {...props} {...{ items }} editable />;
  }
);
