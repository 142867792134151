import { Language, Locale } from '@binhatch/locale';
import { Card, InputWithLabel, Select } from '@binhatch/ui';
import React from 'react';

export const SettingsGeneralPage: React.FC = () => {
  const [locale, setLocale] = Language.useContainer();

  return (
    <Card className="max-w-xl">
      <InputWithLabel
        input={Select}
        items={[Locale.English, Locale.German, Locale.Hungarian, Locale.Romanian].map((locale) => ({
          value: locale,
          name: Object.entries(Locale).find(([, value]) => value === locale)?.[0]
        }))}
        value={locale}
        onChange={setLocale}
      />
    </Card>
  );
};
