import { translations } from '@binhatch/locale';
import { ChevronLeftIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Button } from './Button';

interface Props extends React.ComponentProps<typeof Button> {
  className?: string;
}

export const BackButton: React.FC<React.PropsWithChildren<Props>> = ({ className, children, ...props }) => (
  <Button {...props} className={classNames('inline-flex items-center border-0 text-gray-500', className)}>
    <ChevronLeftIcon className="mr-2 h-5 w-5" />
    <FormattedMessage id={translations.buttons.back} />
  </Button>
);
