import React from 'react';

export type AsComponent = keyof JSX.IntrinsicElements | React.JSXElementConstructor<any>;

export type AsProps<T extends AsComponent> = Omit<React.ComponentProps<T>, 'as'> & {
  as: T;
};

export const As = React.forwardRef(function <T extends AsComponent>(
  { as: Component, ...rest }: React.PropsWithChildren<AsProps<T>>,
  ref: React.Ref<any>
) {
  // @ts-ignore
  return <Component {...rest} {...{ ref }} />;
});
