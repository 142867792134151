import { Dialog, Transition } from '@headlessui/react';
import React from 'react';

export interface InstanceProps<T = void, D = {}> {
  data: D;
  className?: string;
  initialFocus?: React.MutableRefObject<HTMLElement | null>;
  onAction: OnAction<T>;
  onClose(): void;
}

export type Props<P extends InstanceProps<any, any>> = {
  modal: React.ComponentType<P>;
  isOpen: boolean;
  important?: boolean;
} & P;

export interface OnAction<T> {
  (value: T): any;
}

export type InferOnActionParam<A> = A extends OnAction<infer T> ? T : never;

export interface OpenModal<P extends InstanceProps<any, any>> {
  (data: P['data']): Promise<InferOnActionParam<P['onAction']>>;
}

const noop = () => void 0;

export const Modal = <P extends InstanceProps<any, any>>({
  modal: Component,
  isOpen,
  initialFocus,
  important,
  onAction,
  onClose,
  ...rest
}: Props<P>) => (
  <Transition as={React.Fragment} show={isOpen}>
    <Dialog
      {...{ initialFocus }}
      as="div"
      className="fixed inset-0 z-40 overflow-y-auto"
      static
      onClose={important ? noop : onClose}
    >
      <Transition.Child
        as={React.Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed inset-0 bg-black/30" />
      </Transition.Child>

      <div className="fixed inset-0 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="pointer-events-none flex min-h-full flex-1 items-center justify-center">
              {/* @ts-ignore */}
              <Component
                {...rest}
                {...{ initialFocus, onAction, onClose }}
                className="pointer-events-auto relative mx-auto w-full rounded-lg bg-white p-4"
              />
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </div>
    </Dialog>
  </Transition>
);
