import { translations } from '@binhatch/locale';
import { InstanceProps, ModalHeader, ModalLayout, ModalPrimaryButton } from '@binhatch/ui';
import classnames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Tenant } from '@/containers/useTenant';

interface Props extends InstanceProps<void> {}

export const CookieModal = React.forwardRef<HTMLDivElement, Props>(({ initialFocus, className, onAction }, ref) => {
  const { data } = Tenant.useContainer();

  return (
    <div {...{ ref }} className={classnames(className, 'max-w-xl')}>
      <ModalLayout>
        <ModalHeader>
          <FormattedMessage id={translations.modals.cookies.title} />
        </ModalHeader>

        <div>
          <FormattedMessage
            id={translations.modals.cookies.message}
            values={{
              a: (children) => (
                <a className="text-brand hover:underline" href={data?.cookiesURL} rel="noreferrer" target="_blank">
                  {children}
                </a>
              )
            }}
          />
        </div>

        <div className="flex flex-row-reverse">
          <ModalPrimaryButton ref={initialFocus} {...{ onAction }}>
            <FormattedMessage id={translations.buttons.accept} />
          </ModalPrimaryButton>
        </div>
      </ModalLayout>
    </div>
  );
});
