import dayjs from 'dayjs';
import { Period, Progress, Promotion, Target, TargetLevel, UserPromotion } from 'flexinet-api';

const getTimestamp = (date: string) => new Date(date).getTime();

export const getPeriodEndDistance = (period: Period) => {
  return Math.ceil(dayjs(period.endAt).diff(new Date(), 'day', true));
};

export const getPromotionDays = (period: Period) => {
  return Math.max(getPeriodEndDistance(period), 0);
};

const isCurrentPeriod = (period: Period, timestamp: number) => {
  return getTimestamp(period.startAt) <= timestamp && timestamp <= getTimestamp(period.endAt);
};

export const getCurrentPeriod = (periods: Period[]) => {
  const now = Date.now();

  return periods.find((period) => isCurrentPeriod(period, now));
};

export const getFallbackPeriod = (periods: Period[]) => {
  periods = sortPeriods(periods);

  if (!periods.length) return;

  const now = Date.now();

  if (getTimestamp(periods[0].startAt) >= now) return periods[0];

  return periods[periods.length - 1];
};

export const sortPeriods = (periods: Period[]) => {
  return periods
    .slice()
    .map((period) => ({ period, startAt: getTimestamp(period.startAt) }))
    .sort((p1, p2) => p1.startAt - p2.startAt)
    .map(({ period }) => period);
};

export const getCurrency = (promotion?: Promotion | UserPromotion) => {
  // TODO: configure fallback currency during build
  return promotion?.target.currency ?? 'RON';
};

export interface PromotionClientProgress {
  value: number;
  percent: number;
}

export const getPromotionClientProgress = (
  period: Period | undefined,
  progresses: Progress[],
  level: TargetLevel
): PromotionClientProgress => {
  const progressDuringPeriod = period ? progresses.find((p) => p.periodID === period.id) : undefined;
  const totalValue = progressDuringPeriod ? parseFloat(progressDuringPeriod.value) : 0;

  const levelValue = parseFloat(level.value);

  const value = Math.min(totalValue, levelValue);
  const percent = Math.max(Math.min(value / levelValue, 1), 0);

  return { value, percent };
};

export interface LastPromotionClientProgress extends PromotionClientProgress {
  index: number;
  level: TargetLevel;
  claimed: boolean;
}

export const getLastPromotionClientProgress = (
  promotion: UserPromotion | Promotion,
  period: Period | undefined,
  progresses: Progress[],
  getValueFromProgress: (progress: Progress) => number = (progress) => parseFloat(progress.value)
): LastPromotionClientProgress => {
  const progressDuringPeriod = period
    ? progresses.find((p) => p.promotionId === promotion.id && p.periodID === period.id)
    : undefined;

  const value = progressDuringPeriod ? getValueFromProgress(progressDuringPeriod) : 0;

  const reversedLevelIndex = promotion.target.levels.reverse().findIndex((level) => parseFloat(level.value) <= value);

  const index = reversedLevelIndex < 0 ? 0 : promotion.target.levels.length - 1 - reversedLevelIndex;
  const level = promotion.target.levels[index];
  const percent = Math.max(value / parseFloat(level.value), 0);

  return { index, level, value, percent, claimed: !!progressDuringPeriod?.claimedAt };
};
export const getLastCustomDealClientProgress = (
  promotion: UserPromotion | Promotion,
  period: Period | undefined,
  target: Target | undefined,
  progresses: Progress[],
  getValueFromProgress: (progress: Progress) => number = (progress) => parseFloat(progress.value)
): LastPromotionClientProgress => {
  const progressDuringPeriod = period
    ? progresses.find((p) => p.promotionId === promotion.id && p.periodID === period.id)
    : undefined;

  const value = progressDuringPeriod ? getValueFromProgress(progressDuringPeriod) : 0;

  const targetLevels = target?.levels ?? [];
  const reversedLevelIndex = targetLevels.reverse().findIndex((level) => parseFloat(level.value) <= value);

  const index = reversedLevelIndex < 0 ? 0 : targetLevels.length - 1 - reversedLevelIndex;
  const level = targetLevels[index];
  const percent = Math.max(value / parseFloat(level.value), 0);

  return { index, level, value, percent, claimed: !!progressDuringPeriod?.claimedAt };
};
