import { translations } from '@binhatch/locale';
import {
  Card,
  DynamicBackButton,
  Form,
  InputWithLabel,
  LoadingButton,
  PageHeading,
  SubmitError,
  ValidatedField
} from '@binhatch/ui';
import { ProductKind } from 'flexinet-api';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router';
import * as yup from 'yup';

import { productApi } from '@/integrations/api';

const schema = yup
  .object({
    name: yup.string().required().label(translations.fields.leadName.label),
    description: yup.string().required().label(translations.fields.leadDescription.label)
  })
  .required();

export const LeadDetailPage: React.FC = () => {
  const intl = useIntl();
  const navigate = useNavigate();

  const onSubmit = React.useCallback(
    async (values: yup.InferType<typeof schema>) => {
      await productApi.createProductRequest({ kind: ProductKind.Item, ...values, media: [] });

      navigate(-1);
    },
    [navigate]
  );

  return (
    <main className="space-y-2 xl:space-y-6">
      <div>
        <DynamicBackButton />

        <PageHeading title={<FormattedMessage id={translations.pages.leadDetail.title} />} />
      </div>

      <Card className="max-w-3xl space-y-2 xl:space-y-6">
        <div className="text-sm">
          <FormattedMessage id={translations.pages.leadDetail.message} />
        </div>

        <Form {...{ schema, onSubmit }}>
          {({ submitting, submitError, handleSubmit }) => (
            <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
              <ValidatedField
                field={InputWithLabel}
                id="name"
                label={<FormattedMessage id={translations.fields.leadName.label} />}
                name="name"
                placeholder={intl.formatMessage({ id: translations.fields.leadName.placeholder })}
                readOnly={submitting}
                type="text"
              />

              <ValidatedField
                as="textarea"
                field={InputWithLabel}
                id="description"
                inputClassName="min-h-[8rem] h-full resize-none"
                label={<FormattedMessage id={translations.fields.leadDescription.label} />}
                name="description"
                placeholder={intl.formatMessage({ id: translations.fields.leadDescription.placeholder })}
                readOnly={submitting}
                type="text"
              />

              <SubmitError error={submitError} />

              <div className="flex justify-end gap-4">
                <LoadingButton appearance="primary" className="h-10 px-4" loading={submitting} type="submit">
                  <FormattedMessage id={translations.buttons.send} />
                </LoadingButton>
              </div>
            </form>
          )}
        </Form>
      </Card>
    </main>
  );
};
