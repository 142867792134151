export class UiError extends Error {
  constructor(
    message: string,
    public values?: object
  ) {
    super(message);
  }
}

export const isUiError = (error: unknown): error is UiError => error instanceof UiError;
