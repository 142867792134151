import { useRemoteData, useStrictParams } from '@binhatch/hooks';
import { translations } from '@binhatch/locale';
import {
  BonusValue,
  Card,
  DayCount,
  DynamicBackButton,
  PageHeading,
  PromotionArticles,
  PromotionPeriod,
  TargetValue
} from '@binhatch/ui';
import { getCurrency, getCurrentPeriod, getFallbackPeriod, getPromotionDays } from '@binhatch/utility';
import { CalendarIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Outlet } from 'react-router';

import { productApi, promotionApi } from '@/integrations/api';

export const CustomDealDetailPage: React.FC = () => {
  const { promotionId } = useStrictParams<{ promotionId: string }>();

  const promotion = useRemoteData({ key: 'useCustomDeal', promotionId }, async ({ promotionId }) => {
    return promotionApi.getUserPromotion(promotionId).then((r) => r.data);
  });

  const period = React.useMemo(() => {
    if (!promotion.data) return;

    return getCurrentPeriod(promotion.data.periods ?? []) ?? getFallbackPeriod(promotion.data.periods ?? []);
  }, [promotion.data]);

  return (
    <main className="space-y-2 md:space-y-6">
      <div className="flex justify-between gap-4">
        <DynamicBackButton />
      </div>

      <Card className="flex flex-wrap gap-4 md:flex-nowrap md:gap-8">
        <div className="flex flex-1 flex-col items-end gap-4 md:order-last">
          {!!promotion.data && !!period && (
            <div className="flex flex-col flex-wrap items-end gap-4">
              <DayCount count={getPromotionDays(period)} />

              <div className="flex items-center gap-2 whitespace-nowrap">
                <CalendarIcon className="h-6 w-6" />

                <div className="whitespace-nowrap">
                  <PromotionPeriod endAt={new Date(period.endAt)} startAt={new Date(period.startAt)} />
                </div>
              </div>

              {promotion.data.repetition && (
                <FormattedMessage id={translations.enum.repetition[promotion.data.repetition]} />
              )}
            </div>
          )}

          {!!promotion.data && (
            <div className="bg-shade-light hidden gap-2 whitespace-nowrap rounded p-4 xl:grid">
              {promotion.data?.target.levels.map((level, index) => (
                <div className="flex flex-col gap-4" key={index}>
                  <div className="flex items-center justify-between gap-8">
                    <div className="font-semibold">
                      <FormattedMessage id={translations.pages.customDealCreate.targetTotal} />
                    </div>

                    <TargetValue
                      currency={getCurrency(promotion.data)}
                      unit={promotion.data!.target.targetMu}
                      value={parseFloat(level.value)}
                    />
                  </div>

                  <div className="flex items-center justify-between gap-8">
                    <div className="font-semibold">
                      <FormattedMessage id={translations.pages.customDealCreate.bonusTotal} />
                    </div>

                    <BonusValue
                      getProduct={(id) => productApi.getUserProductById(id).then((r) => r.data)}
                      reference={level.bonus.ref}
                      unit={level.bonus.mu}
                      value={level.bonus.value}
                    />
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>

        <div className="flex w-full max-w-xl flex-col gap-2 md:gap-4">
          <PageHeading title={promotion.data?.name} />

          <PromotionArticles tags={promotion.data?.tags ?? {}} />

          <div>{promotion.data?.description}</div>
        </div>
      </Card>

      {!!promotion.data && <Outlet context={promotion.data} />}
    </main>
  );
};
