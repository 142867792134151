import { useRemoteData } from '@binhatch/hooks';
import React from 'react';
import { useLocalStorage } from 'react-use';
import { createContainer } from 'unstated-next';

import { Locale } from '../locales';

interface UseLanguageOptions {
  locale?: Locale;
  onChange?(locale: Locale): unknown;
}

export const getLocale = (value: string) => {
  return Object.values(Locale).some((l) => l === value) ? (value as Locale) : undefined;
};

const useLanguage = ({ locale: defaultLocale, onChange }: UseLanguageOptions = {}) => {
  const [locale, setLocale] = useLocalStorage(
    'locale',
    defaultLocale ?? getLocale(window.navigator.language?.substring(0, 2)) ?? Locale.English
  );

  const messages = useRemoteData({ key: 'useMessages', locale, skip: !locale }, ({ locale }) =>
    import(`../lang/${locale}.json`).then((r) => r.default)
  );

  const update = React.useCallback(
    (locale: Locale) => {
      setLocale(locale);
      onChange?.(locale);
    },
    [onChange]
  );

  return [locale!, update, messages] as const;
};

export const Language = createContainer(useLanguage);
