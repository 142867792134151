import React from 'react';
import { useField } from 'react-final-form';

import classNames from 'classnames';
import { AsComponent, AsProps } from '../As';
import { FormattedValidationMessage } from './FormattedValidationMessage';

const usePropagatedHandler = <T extends React.FocusEvent<HTMLElement>, R>(
  fieldCallback: (event: T) => R,
  propCallback?: (event: T) => R
) => {
  return React.useCallback(
    (event: T) => {
      if (!propCallback) return fieldCallback(event);

      const result = propCallback(event) as any;

      return fieldCallback(result ?? event);
    },
    [fieldCallback, propCallback]
  );
};

type Props<F extends AsComponent, T extends AsComponent> = Omit<AsProps<F>, 'as' | 'value' | 'onChange'> &
  Omit<AsProps<T>, 'as' | 'value' | 'onChange'> & {
    field: AsProps<F>['as'];
    as?: AsProps<T>['as'];
    parse?(value: any, name: string): any;
    format?(value: any, name: string): any;
    allowNull?: boolean;
    fieldClassName?: string;
  };

export const ValidatedField = React.forwardRef(function ValidatedField<F extends AsComponent, T extends AsComponent>(
  {
    field: Field,
    name,
    value,
    type,
    validated = true,
    parse,
    format,
    allowNull,
    className,
    fieldClassName,
    ...props
  }: Props<F, T>,
  ref: React.Ref<any>
) {
  const { meta, input } = useField(name, { value, allowNull, format, parse, type });

  const error = React.useMemo(() => {
    const invalid = meta.submitError ? !!meta.submitError && !meta.dirtySinceLastSubmit : meta.invalid;

    if (!invalid) return;

    const visible = !!meta.touched && invalid; // !meta.active &&
    const id = meta.error?.id || meta.submitError;
    const values = meta.error?.values;

    return { id, values, visible };
  }, [meta]);

  const onFocus = usePropagatedHandler(input.onFocus, props.onFocus);
  const onBlur = usePropagatedHandler(input.onBlur, props.onBlur);
  const onChange = usePropagatedHandler(input.onChange, props.onChange);

  return (
    <div {...{ className }}>
      <Field
        {...{ ref }}
        {...props}
        {...input}
        invalid={!!error?.visible}
        {...{ onBlur, onChange, onFocus, type }}
        className={fieldClassName}
      />

      {validated && !!error && (
        <FormattedValidationMessage
          {...error}
          className={classNames({ 'mt-1': !!error?.visible })}
          visible={!!error?.visible}
        />
      )}
    </div>
  );
});
