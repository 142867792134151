import { MinusCircleIcon, PlusCircleIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import React from 'react';

export const ConditionSeparator: React.FC<{
  negation: boolean;
}> = ({ negation }) => {
  const Icon = !negation ? PlusCircleIcon : MinusCircleIcon;

  return (
    <div className={classNames('relative h-full w-0.5', !negation ? 'bg-success text-success' : 'text-error bg-error')}>
      <div className="bg-shade-light absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 rounded-full p-1">
        <Icon className="h-6 w-6" />
      </div>
    </div>
  );
};
