import classNames from 'classnames';
import React from 'react';

import { MaybeImage } from './MaybeImage';

interface Props {
  src?: string;
  square?: boolean;
  className?: string;
}

export const ProductImage: React.FC<Props> = ({ src, square, className }) => (
  <div className={classNames('relative', className)}>
    <div className={classNames('flex w-full items-center justify-center', { 'aspect-square': square })}>
      <MaybeImage {...{ src }} className="max-h-full max-w-full flex-shrink-0 overflow-hidden rounded object-contain" />
    </div>
  </div>
);
