import classnames from 'classnames';
import React from 'react';

interface Props {
  className?: string;
}

export const ModalLayout: React.FC<React.PropsWithChildren<Props>> = ({ className, ...props }) => (
  <div {...props} className={classnames('flex w-full flex-col gap-4', className)} />
);

interface ModalIconLayoutProps {
  icon: React.ReactNode;
}

export const ModalIconLayout: React.FC<React.PropsWithChildren<ModalIconLayoutProps>> = ({ icon, children }) => (
  <div className="flex flex-col gap-4 md:flex-row">
    <div className="flex flex-shrink-0 justify-center md:block">{icon}</div>

    <div className="flex-1">{children}</div>
  </div>
);
