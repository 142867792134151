import { isUiError, translations } from '@binhatch/locale';
import { ApiErrorResult, isApiError } from '@binhatch/utility';
import { AxiosError } from 'axios';
import React from 'react';

import { FormattedValidationMessage } from './form/FormattedValidationMessage';

interface Props {
  error?: Error;
  className?: string;
}

const getMessageId = (error: AxiosError<ApiErrorResult>) => {
  return error?.response?.data.key ? translations.errors.api[error?.response?.data.key] ?? translations.errors.unhandled : translations.errors.unhandled;
};

export const SubmitError: React.FC<Props> = ({ error, className }) => {
  if (!error) return null;

  const { id, values } = React.useMemo(() => {
    if (isApiError(error)) return { id: getMessageId(error) };
    if (isUiError(error)) return { id: error.message, values: error.values };
    return { id: translations.errors.unhandled };
  }, [error]);

  return <FormattedValidationMessage {...{ id, values, className }} visible />;
};
