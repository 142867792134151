import { EventProperties, EventType } from './event-properties';
import { Analytics } from './types';
import { AnalyticsUser } from './user';
import { UserTrackerCallback } from './user-tracker';

class AnalyticsService implements Analytics {
  constructor(private providers: Analytics[]) {}

  private executeOnProvider(callback: (provider: Analytics) => void) {
    this.providers.forEach((provider) => {
      try {
        callback(provider);
      } catch (e) {
        console.error(e);
      }
    });
  }

  trackPage(path: string): void {
    this.executeOnProvider((provider) => provider.trackPage(path));
  }

  trackEvent<E extends EventType>(type: E, event: EventProperties[E]): void {
    this.executeOnProvider((provider) => provider.trackEvent(type, event));
  }

  trackUser(callback: UserTrackerCallback): void {
    this.executeOnProvider((provider) => provider.trackUser(callback));
  }

  setCurrentUser(user?: AnalyticsUser): void {
    this.executeOnProvider((provider) => provider.setCurrentUser(user));
  }
}

export const analytics = new AnalyticsService([]);
