import { QueryParamConfig, decodeSingleQueryParam, useQueryParams, useRemoteData } from '@binhatch/hooks';
import { translations } from '@binhatch/locale';
import {
  AsyncButton,
  BonusValue,
  Card,
  DynamicBackButton,
  EmptyState,
  LoadingState,
  PageLoaderHeading,
  Pagination,
  PromotionArticles,
  PromotionClaimed,
  PromotionPeriod
} from '@binhatch/ui';
import { getAllFromApi } from '@binhatch/utility';
import { Period, PromotionSortByField, PromotionType, SortDirection, TargetLevel } from 'flexinet-api';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { productApi, progressApi, promotionApi } from '@/integrations/api';

import { Auth } from '@/containers/useAuth';

import { useBalance } from '@/hooks/useBalance';

import { PeriodExpiration } from './components/PeriodExpiration';

const page: QueryParamConfig<string | undefined> = {
  decode: (value) => decodeSingleQueryParam(value, undefined),
  encode: (value) => value
};

const config = { page };

export const CustomDealClaimListPage: React.FC = () => {
  const { reload } = useBalance();
  const { context } = Auth.useContainer();

  const [query, updateQuery] = useQueryParams({ config });
  const [claimed, setClaimed] = React.useState<string[]>([]);

  const promotions = useRemoteData({ key: `useClaimablePromotions`, page: query.page }, ({ page }) =>
    promotionApi
      .listUserPromotions(
        page,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        PromotionSortByField.EndsAt,
        SortDirection.Asc,
        true,
        PromotionType.CustomDeal
      )
      .then((r) => r.data)
      .then(async ({ promotions, ...r }) => {
        const promotionIds = promotions.map((p) => p.id);

        const progress = promotionIds.length
          ? await getAllFromApi(
              () => progressApi.listUserProgress(promotionIds).then((r) => r.data),
              (r) => r.data
            )
          : [];

        return {
          ...r,
          promotions: promotions.map((promotion) => {
            const rewards =
              promotion.periods?.reduce<{ period: Period; level: TargetLevel }[]>((rewards, period) => {
                const progressDuringPeriod = progress.filter((p) => p.periodID === period.id);
                const totalProgress = progressDuringPeriod.reduce((total, p) => total + parseFloat(p.value), 0);

                const index = promotion.target.levels
                  .reverse()
                  .findIndex((level) => parseFloat(level.value) <= totalProgress);

                if (index < 0) return rewards;

                const level = promotion.target.levels[promotion.target.levels.length - 1 - index];

                rewards.push({ period, level });

                return rewards;
              }, []) ?? [];

            return { promotion, rewards };
          })
        };
      })
  );

  return (
    <main className="space-y-2 md:space-y-6">
      <div className="flex items-center justify-between gap-4">
        <div className="flex items-center gap-4">
          <div>
            <DynamicBackButton />

            <PageLoaderHeading loading={promotions.isLoading || promotions.isValidating}>
              <FormattedMessage id={translations.pages.promotionClaimList.title} />
            </PageLoaderHeading>
          </div>
        </div>
      </div>

      <LoadingState loading={promotions.isLoading || promotions.isValidating}>
        {!promotions.isLoading && !promotions.data?.promotions.length && (
          <EmptyState>
            <FormattedMessage id={translations.pages.promotionList.empty} />
          </EmptyState>
        )}

        <ul className="grid gap-4">
          {promotions.data?.promotions.map(({ promotion, rewards }) => (
            <Card className="flex flex-col gap-4 shadow" key={promotion.id}>
              <div className="flex flex-wrap gap-4">
                <div className="flex flex-1 flex-col gap-2">
                  <div className="font-semibold">{promotion.name}</div>

                  <PromotionArticles tags={promotion.tags} />

                  <div className="line-clamp-2 max-w-3xl">{promotion.description}</div>
                </div>

                <div className="w-full lg:w-auto">
                  {claimed.includes(promotion.id) ? (
                    <PromotionClaimed />
                  ) : (
                    <AsyncButton
                      appearance="primary"
                      className="h-10 w-full px-4"
                      disabled={context?.client?.isSuspended}
                      onClick={async () => {
                        await promotionApi.claimPromotionUserAll(promotion.id);
                        setClaimed((claimed) => [...claimed, promotion.id]);
                        await reload();
                      }}
                    >
                      <FormattedMessage id={translations.pages.promotionClaimList.claim} />
                    </AsyncButton>
                  )}
                </div>
              </div>

              <ul className="flex flex-wrap gap-2">
                {rewards?.map(({ period, level }, index) => (
                  <li className="border-shade flex gap-4 rounded border p-2" key={index}>
                    <div>
                      <PromotionPeriod endAt={new Date(period.endAt)} startAt={new Date(period.startAt)} />
                    </div>

                    <BonusValue
                      getProduct={(id) => productApi.getUserProductById(id).then((r) => r.data)}
                      reference={level.bonus.ref}
                      unit={level.bonus.mu}
                      value={level.bonus.value}
                    />

                    <PeriodExpiration {...{ period }} />
                  </li>
                ))}
              </ul>
            </Card>
          ))}
        </ul>
      </LoadingState>

      <Pagination
        hasNext={!!promotions.data?.nextToken}
        // hasPrevious={!!tales.data?.previousToken}
        onNext={() => updateQuery({ page: promotions.data?.nextToken })}
        // onPrevious={() => updateQuery({ page: tales.data?.previousToken })}
      />
    </main>
  );
};
