import { translations } from '@binhatch/locale';
import classNames from 'classnames';
import { Period, Promotion, UserPromotion } from 'flexinet-api';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { CoinValue } from '../CoinValue';
import { DayCount, PromotionPeriod } from '../promotions';

interface Props {
  promotion: Promotion | UserPromotion;
  days: number;
  period?: Period;
  className?: string;
}

export const CustomDealItemContainer: React.FC<React.PropsWithChildren<{ className?: string }>> = (props) => (
  <div {...props} className={classNames('w-full overflow-hidden rounded-lg bg-white shadow', props.className)} />
);

export const CustomDealItem: React.FC<Props> = ({ promotion, period, days, className }) => (
  <React.Fragment>
    <div className={classNames('flex flex-1', className)}>
      <div className="flex flex-1 justify-between gap-4">
        <div className="flex flex-1 flex-col gap-1">
          <div className="font-semibold">{promotion.name}</div>

          <div className="line-clamp-2 text-sm">{promotion.description}</div>
        </div>

        <div className="flex flex-col items-end gap-1 text-sm">
          <DayCount count={days} />

          {!!period && (
            <div>
              <PromotionPeriod endAt={new Date(period.endAt)} startAt={new Date(period.startAt)} />
            </div>
          )}

          {!!promotion.repetition && (
            <div>
              <FormattedMessage id={translations.enum.repetition[promotion.repetition]} />
            </div>
          )}
        </div>
      </div>
    </div>

    <CoinValue value={parseFloat(promotion.target.levels[0]?.value) ?? 0} />
  </React.Fragment>
);
