import { balanceApi } from '@/integrations/api';

import { useRemoteData } from '@binhatch/hooks';

interface UseBalancesOptions {
  ids?: string[];
}

export const useBalances = ({ ids }: UseBalancesOptions) => {
  return useRemoteData({ key: `useBalances`, ids }, async ({ ids }) => {
    const balances = ids?.length ? await balanceApi.getUserBalance(ids).then((r) => r.data.balances) : [];

    return new Map(balances.map((balance) => [balance.beneficiary.value, balance]));
  });
};
