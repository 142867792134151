import classNames from 'classnames';
import React from 'react';
import { Field } from 'react-final-form';

import { AsComponent } from '../As';

import { InputProps } from './Input';
import { Item } from './Select';

const ButtonGroupRadio = React.forwardRef(function ButtonGroupRadio<T extends AsComponent>(
  { invalid, className, children, ...props }: React.PropsWithChildren<InputProps<T>>,
  ref: React.Ref<any>
) {
  return (
    <div className="group">
      <input {...props} {...{ ref }} className="peer m-0 block h-0 w-0 opacity-0" />

      <label
        className={classNames(
          '-ml-px inline-flex h-full cursor-pointer items-center justify-center rounded px-2 text-sm font-semibold focus:z-10 group-first:mx-0 peer-disabled:cursor-default',
          props.checked ? 'border-brand bg-brand text-white' : 'border-border hover:bg-shade'
        )}
        htmlFor={props.id}
        id={props.id}
      >
        {children}
      </label>
    </div>
  );
});

type Props<T extends AsComponent> = InputProps<T> & {
  items: Item<string>[];
};

export const ButtonRadioInput = React.forwardRef(function ButtonRadioInput<T extends AsComponent>(
  { id, items, name, className, ...props }: Props<T>,
  ref: React.Ref<any>
) {
  return (
    <div
      className={classNames('bg-shade-light border-shade-light isolate inline-flex gap-2 rounded border-4', className)}
    >
      {items.map((item) => (
        <Field key={item.value} {...props} {...{ name }} value={item.value}>
          {({ input }) => (
            <ButtonGroupRadio {...{ ref }} id={`${id}-${item.value}`} {...input}>
              {item.name}
            </ButtonGroupRadio>
          )}
        </Field>
      ))}
    </div>
  );
});
