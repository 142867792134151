import roMessage from '../lang/ro.json';

export type LocaleTranslation = typeof roMessage;

const traverse = (obj: any, path = ''): any => {
  if (obj === null) return null;

  if (typeof obj !== 'object') return path;

  return Object.keys(obj).reduce<any>((result, key) => {
    result[key] = traverse(obj[key], path ? `${path}.${key}` : key);
    return result;
  }, {});
};

export const translations: LocaleTranslation = traverse(roMessage);
