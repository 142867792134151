import { useRemoteData } from '@binhatch/hooks';
import { BonusValue, Card, PromotionTargetProgress, Select } from '@binhatch/ui';
import { getAllFromApi, getCurrentPeriod, getFallbackPeriod, getPromotionClientProgress } from '@binhatch/utility';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { UserPromotion } from 'flexinet-api';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useOutletContext } from 'react-router';

import { productApi, progressApi } from '@/integrations/api';
import { translations } from '@binhatch/locale';

export const PromotionProgressPage: React.FC = () => {
  const promotion = useOutletContext<UserPromotion>();
  const intl = useIntl();

  const [periodId, setPeriodId] = React.useState(
    () => getCurrentPeriod(promotion.periods ?? [])?.id ?? getFallbackPeriod(promotion.periods ?? [])?.id
  );

  const period = React.useMemo(() => promotion.periods?.find((p) => p.id === periodId), [promotion.periods, periodId]);

  const progress = useRemoteData(
    { key: 'useProgress', promotionId: promotion.id, periodId: period?.id },
    async ({ promotionId, periodId }) => {
      return await getAllFromApi(
        (nextToken) => progressApi.listUserProgress([promotionId], periodId, nextToken).then((r) => r.data),
        (r) => r.data
      );
    }
  );

  const levels = React.useMemo(() => {
    return promotion.target.levels.map((level, index) => ({
      index,
      level,
      ...getPromotionClientProgress(period, progress.data ?? [], level),
      claimed: false
    }));
  }, [progress, promotion, period]);

  return (
    <Card className="flex flex-col gap-8">
      <div className="flex items-center gap-8">
        <div className="font-semibold">
          <FormattedMessage id={translations.pages.promotionDetail.period.label} />
        </div>

        <Select
          items={
            promotion.periods?.map((period) => ({
              value: period.id,
              name: intl.formatDate(period.startAt, { year: 'numeric', month: 'long', day: 'numeric' })
            })) ?? []
          }
          placeholder={intl.formatMessage({ id: translations.pages.promotionDetail.period.placeholder })}
          value={periodId}
          onChange={setPeriodId}
        />
      </div>

      <ul className="grid gap-4">
        {levels.map((progress, index) => (
          <li key={index}>
            <PromotionTargetProgress {...{ promotion, progress }}>
              <div className="flex flex-1 justify-end">
                <BonusValue
                  getProduct={(id) => productApi.getUserProductById(id).then((r) => r.data)}
                  reference={progress.level.bonus.ref}
                  unit={progress.level.bonus.mu}
                  value={progress.level.bonus.value}
                />
              </div>
            </PromotionTargetProgress>
          </li>
        ))}
      </ul>

      <div className="flex gap-2 rounded bg-slate-100 p-4">
        <InformationCircleIcon className="h-6 w-6 flex-shrink-0" />
        <FormattedMessage id={translations.pages.promotionDetail.note} />
      </div>
    </Card>
  );
};
