import { useRemoteData } from '@binhatch/hooks';
import { BonusMu, Product } from 'flexinet-api';
import React from 'react';

import { Coin } from '../Coin';
import { ProductImage } from '../ProductImage';

interface Props {
  unit: BonusMu;
  value: number;
  reference?: string;
  getProduct(productId: string): Promise<Product>;
}

export const BonusValue: React.FC<Props> = ({ unit, value, reference, getProduct }) => {
  const productId = unit === BonusMu.Product && reference ? reference : undefined;

  const product = useRemoteData({ key: 'useProduct', productId, skip: !productId }, async ({ productId }) => {
    if (!productId) throw new Error(`Product id is missing`);

    return getProduct(productId);
  });

  switch (unit) {
    case BonusMu.Product:
      return (
        <div className="flex items-center gap-2">
          <ProductImage className="w-5" src={product.data?.media[0]} />
          {product.data?.name}
        </div>
      );
    case BonusMu.Points:
      return <Coin>{value}</Coin>;
  }

  throw new Error('Unknown bonus unit.');
};
