import React from 'react';

import { Loading, LoadingIndicator } from './Loading';
import { PageHeading } from './PageHeading';

interface Props {
  loading: boolean;
  className?: string;
}

export const PageLoaderHeading: React.FC<React.PropsWithChildren<Props>> = ({ loading, className, children }) => (
  <PageHeading {...{ className }} title={children}>
    <Loading visible={loading}>
      <LoadingIndicator className="h-5 w-5" />
    </Loading>
  </PageHeading>
);
