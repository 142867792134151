import { QueryParamConfig, decodeSingleQueryParam, useQueryParams } from '@binhatch/hooks';
import dayjs from 'dayjs';

const parseDateWithFormat = (date: string | undefined, format: string) => {
  if (!date) return;

  const value = dayjs(date, format, true);

  if (!value.isValid()) return;

  return value.toDate();
};

const page: QueryParamConfig<string | undefined> = {
  decode: (value) => decodeSingleQueryParam(value, undefined),
  encode: (value) => value
};

const before: QueryParamConfig<Date | undefined> = {
  decode: (value) => parseDateWithFormat(decodeSingleQueryParam(value, undefined), 'YYYY-MM-DD'),
  encode: (value) => value?.toISOString()
};

const after: QueryParamConfig<Date | undefined> = {
  decode: (value) => parseDateWithFormat(decodeSingleQueryParam(value, undefined), 'YYYY-MM-DD'),
  encode: (value) => value?.toISOString()
};

const search: QueryParamConfig<string | undefined> = {
  decode: (value) => decodeSingleQueryParam(value, undefined),
  encode: (value) => (value ? value : undefined)
};

const config = { page, search, before, after };

export const useOrderParams = () => useQueryParams({ config });
