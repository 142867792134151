import { useRemoteData } from '@binhatch/hooks';
import { translations } from '@binhatch/locale';
import { BonusValue, Card, PromotionTargetProgress } from '@binhatch/ui';
import { getAllFromApi, getCurrentPeriod, getFallbackPeriod, getLastCustomDealClientProgress } from '@binhatch/utility';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { UserPromotion } from 'flexinet-api';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useOutletContext } from 'react-router';

import { productApi, progressApi } from '@/integrations/api';

export const CustomDealProgressPage: React.FC = () => {
  const promotion = useOutletContext<UserPromotion>();

  const period = React.useMemo(
    () => getCurrentPeriod(promotion.periods ?? []) ?? getFallbackPeriod(promotion.periods ?? []),
    [promotion.periods]
  );

  const progress = useRemoteData(
    { key: 'useCustomDealProgress', promotionId: promotion.id, periodId: period?.id },
    async ({ promotionId, periodId }) => {
      return await getAllFromApi(
        (nextToken) => progressApi.listUserProgress([promotionId], periodId, nextToken).then((r) => r.data),
        (r) => r.data
      );
    }
  );

  const groups = React.useMemo(() => {
    return (
      promotion.ruleGroups?.map((group) => ({
        rule: group.rules[0],
        progress: getLastCustomDealClientProgress(promotion, period, group.target, progress.data ?? [], (progress) => {
          if (!group.id || !progress.value_detailed?.[group.id]) return 0;
          return parseFloat(progress.value_detailed[group.id]);
        })
      })) ?? []
    );
  }, [promotion, period, progress.data]);

  return (
    <Card className="flex flex-col gap-8">
      <ul className="grid gap-4">
        {groups.map(({ rule, progress }, index) => (
          <PromotionTargetProgress
            key={index}
            name={`${rule.tagKey}: ${rule.value?.value ?? ''}`}
            {...{ promotion, progress }}
          >
            <div className="flex flex-1 justify-end">
              <BonusValue
                getProduct={(id) => productApi.getUserProductById(id).then((r) => r.data)}
                reference={progress.level.bonus.ref}
                unit={progress.level.bonus.mu}
                value={progress.level.bonus.value}
              />
            </div>
          </PromotionTargetProgress>
        ))}
      </ul>

      <div className="flex gap-2 rounded bg-slate-100 p-4">
        <InformationCircleIcon className="h-6 w-6 flex-shrink-0" />
        <FormattedMessage id={translations.pages.promotionDetail.note} />
      </div>
    </Card>
  );
};
