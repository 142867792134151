import { BeneficiaryKind } from 'flexinet-api';
import { generatePath } from 'react-router';

interface RouteConfig {
  [x: string]: string | RouteConfig | Function;
}

const createRoutes = <C extends RouteConfig>(base: string, config: C): C => {
  return Object.entries(config).reduce<C>((config, [key, value]) => {
    switch (typeof value) {
      case 'string': {
        // @ts-ignore
        config[key] = `${base}${value}`;
        break;
      }
      case 'function': {
        // @ts-ignore
        config[key] = value;
        break;
      }
      case 'object': {
        // @ts-ignore
        config[key] = createRoutes(base, value);
        break;
      }
    }

    return config;
  }, config);
};

const login = `/login`;

const forgotPassword = createRoutes(`/forgot-password`, {
  send: '',
  reset: '/reset'
});

const profile = createRoutes(`/profile`, {
  root: '',
  transactions: createRoutes('/transactions', {
    [BeneficiaryKind.Client]: '/client',
    [BeneficiaryKind.User]: '/user'
  })
});

const promotions = createRoutes('/promotions', {
  root: '',
  claims: '/claims',
  one: '/:promotionId',
  config: '/:promotionId/config',
  getOne: (o: { promotionId: string }, path?: string) => generatePath(path ?? promotions.one, o)
});

const customDeals = createRoutes('/custom-deals', {
  root: '',
  claims: '/claims',
  one: '/:promotionId',
  config: '/:promotionId/config',
  getOne: (o: { promotionId: string }, path?: string) => generatePath(path ?? customDeals.one, o)
});

const products = createRoutes('/products', {
  one: '/:productId',
  getOne: (o: { productId: string }) => generatePath(products.one, o)
});

const orders = createRoutes('/orders', {
  root: '',
  one: '/:orderId',
  getOne: (o: { orderId: string }) => generatePath(orders.one, o)
});

const leads = createRoutes('/leads', {
  root: '',
  new: '/new'
});

const shop = createRoutes('/shop', {
  root: '',
  cart: '/cart',
  products,
  leads
});

const users = createRoutes('/users', {
  root: '',
  one: '/:userId',
  orders: '/:userId/orders',
  transactions: '/:userId/transactions',
  getOne: (o: { userId: string }, path?: string) => generatePath(path ?? users.orders, o)
});

const notifications = '/notifications';

const settings = createRoutes('/settings', {
  root: '',
  notifications: '/notifications'
});

export const urls = {
  login,
  forgotPassword,
  profile,
  promotions,
  customDeals,
  orders,
  shop,
  users,
  notifications,
  settings
};
