import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';

import logo from './logo.svg';

interface Props {
  className?: string;
}

export const Logo: React.FC<Props> = ({ className }) => (
  <Link aria-label="Navigate to the main page" className={classNames('relative block uppercase', className)} to="/">
    <img alt="Logo of Flexinet" className="h-full" src={logo} />
  </Link>
);
