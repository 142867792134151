import { QueryParamConfig, decodeSingleQueryParam, useQueryParams, useRemoteData } from '@binhatch/hooks';
import { translations } from '@binhatch/locale';
import { EmptyState, LoadingState, PageLoaderHeading, Pagination, SearchInput } from '@binhatch/ui';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { userApi } from '@/integrations/api';
import { urls } from '@/utils/url';

import { ClientUserItem } from '@/components/ClientUserItem';
import { useBalances } from '@/hooks/useBalances';

const page: QueryParamConfig<string | undefined> = {
  decode: (value) => decodeSingleQueryParam(value, undefined),
  encode: (value) => value
};

const search: QueryParamConfig<string | undefined> = {
  decode: (value) => decodeSingleQueryParam(value, undefined),
  encode: (value) => (value ? value : undefined)
};

const config = { page, search };

export const UserListPage: React.FC = () => {
  const intl = useIntl();
  const [query, updateQuery] = useQueryParams({ config });

  const users = useRemoteData(
    { key: `useUsers`, search: query.search, page: query.page },
    async ({ search, page: nextToken }) => userApi.listCustomerUsers(nextToken, search, undefined).then((r) => r.data)
  );

  const balances = useBalances({ ids: users.data?.users.map((u) => u.id) });

  return (
    <main className="space-y-2 xl:space-y-6">
      <div className="flex items-center justify-between gap-4">
        <PageLoaderHeading loading={users.isLoading || users.isValidating}>
          <FormattedMessage id={translations.pages.userList.title} />
        </PageLoaderHeading>

        <div className="flex items-center gap-4">
          <SearchInput
            className="w-full md:w-72"
            placeholder={intl.formatMessage({ id: translations.pages.promotionList.search })}
            value={query.search}
            onChange={(search: string) => updateQuery({ search })}
          />
        </div>
      </div>

      <LoadingState loading={users.isLoading || users.isValidating}>
        {!users.isLoading && !users.data?.users.length && (
          <EmptyState>
            <FormattedMessage id={translations.pages.userList.empty} />
          </EmptyState>
        )}

        <ul className="grid min-h-[7rem] gap-4">
          {users.data?.users.map((user) => (
            <li key={user.id}>
              <Link className="block" state={{ from: 1 }} to={urls.users.getOne({ userId: user.id })}>
                <ClientUserItem {...{ user }} balance={balances.data?.get(user.id)} />
              </Link>
            </li>
          ))}
        </ul>
      </LoadingState>

      <Pagination
        hasNext={!!users.data?.nextToken}
        // hasPrevious={!!tales.data?.previousToken}
        onNext={() => updateQuery({ page: users.data?.nextToken })}
        // onPrevious={() => updateQuery({ page: tales.data?.previousToken })}
      />
    </main>
  );
};
