import React from 'react';
import { useLocation, useNavigate } from 'react-router';

import { BackButton } from './BackButton';

export const DynamicBackButton: React.FC = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  if (!state?.from) return null;

  return <BackButton onClick={() => navigate(-1)} />;
};
