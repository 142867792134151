import { MinusIcon, PlusIcon } from '@heroicons/react/20/solid';
import classnames from 'classnames';
import * as React from 'react';

interface Props {
  value: number;
  step?: number;
  min?: number;
  max?: number;
  className?: string;
  readOnly?: boolean;
  disabled?: boolean;
  invalid?: boolean;
  onChange(value: number): void;
}

export const Stepper: React.FC<Props> = ({
  value,
  step = 1,
  min,
  max,
  invalid,
  readOnly,
  disabled,
  className,
  onChange,
  ...props
}) => {
  const onDecrement = React.useCallback(() => {
    if (readOnly || disabled) return;
    return onChange(Math.max(Math.min(value - step, max ?? Infinity), min ?? -Infinity));
  }, [readOnly, disabled, value, step, min, max, onChange]);

  const onIncrement = React.useCallback(() => {
    if (readOnly || disabled) return;
    return onChange(Math.max(Math.min(value + step, max || Infinity), min ?? -Infinity));
  }, [readOnly, disabled, value, step, min, max, onChange]);

  return (
    <div className={classnames('inline-flex w-24', className)}>
      <button
        className="relative z-0 flex w-10 flex-shrink-0 items-center justify-center"
        type="button"
        onClick={onDecrement}
      >
        <MinusIcon className="h-5 w-5 stroke-2" />
      </button>

      <div className="flex flex-1 items-center justify-center">
        <input
          {...{ value, disabled }}
          {...props}
          className="form-input border-shade -mx-10 h-full w-24 rounded text-center text-sm"
          readOnly
        />
      </div>

      <button
        className="relative z-0 flex w-10 flex-shrink-0 items-center justify-center"
        type="button"
        onClick={onIncrement}
      >
        <PlusIcon className="h-5 w-5" />
      </button>
    </div>
  );
};
