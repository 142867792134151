import React from 'react';
import { useAsyncFn as useLegacyAsyncFn } from 'react-use';

/**
 * We need to patch the useAsyncFn because when an error happens the error
 * is returned by the function that makes error handling a PITA.
 * Reference: https://github.com/streamich/react-use/issues/1768
 */
// @ts-expect-error
export const useAsyncFn: typeof useLegacyAsyncFn = (...args) => {
  const [state, legacyAction] = useLegacyAsyncFn(...args);

  const action: typeof legacyAction = React.useCallback(
    // @ts-ignore
    async (...args) => {
      const result = await legacyAction(...args);

      if (result instanceof Error) throw result;

      return result;
    },
    [legacyAction]
  );

  return [state, action] as const;
};
