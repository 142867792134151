import { SystemUserDetails, User, UserDetails } from 'flexinet-api';

export const getUserName = (user: User) => {
  return [user.details.firstName, user.details.lastName].filter(Boolean).join(' ');
};

export const isSystemUserDetails = (
  details: UserDetails
): details is SystemUserDetails & {
  source: 'system';
} => {
  return details.source === 'system';
};
