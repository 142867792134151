import { translations } from '@binhatch/locale';
import { getPeriodEndDistance } from '@binhatch/utility';
import classNames from 'classnames';
import { Period } from 'flexinet-api';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Configuration } from '@/containers/useConfiguration';

interface Props {
  period: Period;
}

export const PeriodExpiration: React.FC<Props> = ({ period }) => {
  const [{ value: configuration }] = Configuration.useContainer();

  const days = getPeriodEndDistance(period) + (configuration.userClaimGracePeriod ?? 0);

  return (
    <div className={classNames('rounded px-2', days > 3 ? 'bg-warning-light' : 'bg-error-light')}>
      <FormattedMessage id={translations.components.periodExpiration.name} values={{ days }} />
    </div>
  );
};
