import classNames from 'classnames';
import React from 'react';

interface Props {
  size?: string;
  className?: string;
  visible?: boolean;
}

export const Skeleton: React.FC<React.PropsWithChildren<Props>> = ({ visible, size, className, children }) => {
  const visibleNormalized = visible === undefined ? !children : !!visible;

  if (visibleNormalized) return <div className={classNames('rounded bg-slate-100', size ?? 'h-4', className)} />;

  return <React.Fragment>{children}</React.Fragment>;
};
