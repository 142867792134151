export interface PaginatedResult {
  nextToken?: string;
}

export const getAllFromApi = <T extends PaginatedResult, I>(
  callback: (nextPage?: string) => Promise<T>,
  builder: (result: T) => I[]
) => {
  const iterator = async (nextPage?: string): Promise<I[]> => {
    const result = await callback(nextPage);
    const items = builder(result);

    if (!result.nextToken) return items;

    return [...items, ...(await iterator(result.nextToken))];
  };

  return iterator();
};
