import classNames from 'classnames';
import React from 'react';

import { AsComponent } from '../As';
import { Coin } from '../Coin';

import { Input, InputProps } from './Input';
import { NumberInput } from './NumberInput';

type Props<T extends AsComponent> = InputProps<T> & {
  containerClassName?: string;
};

export const CoinInput = React.forwardRef(function CoinInput<T extends AsComponent>(
  { containerClassName, className, ...props }: Props<T>,
  ref: React.Ref<any>
) {
  return (
    <div className={classNames('relative', containerClassName)}>
      <Coin className="pointer-events-none absolute left-2 top-1/2 -translate-y-1/2" />

      <Input
        {...{ ref }}
        {...props}
        as={NumberInput}
        className={classNames('pl-10 text-right', className)}
        includeThousandsSeparator
      />
    </div>
  );
});
