import { Promotion } from 'flexinet-api';
import React from 'react';

import car from './assets/car.svg';
import motorcycle from './assets/motorcycle.svg';
import truck from './assets/truck.svg';

export enum PromotionArticleTag {
  Car = 'car',
  Motorcycle = 'motorcycle',
  Truck = 'truck'
}

const assets = { car, motorcycle, truck };

interface PromotionArticleProps {
  tag: PromotionArticleTag;
}

export const PromotionArticle: React.FC<PromotionArticleProps> = ({ tag }) => (
  <img className="h-5 w-5" src={assets[tag]} />
);

interface PromotionArticlesProps {
  tags: Promotion['tags'];
}

export const PromotionArticles: React.FC<PromotionArticlesProps> = ({ tags }) => {
  const items = React.useMemo(() => Object.values(PromotionArticleTag).filter((key) => !!tags[key]), [tags]);

  if (!items.length) return null;

  return (
    <ul className="flex items-center gap-4">
      {items.map((tag) => (
        <PromotionArticle key={tag} {...{ tag }} />
      ))}
    </ul>
  );
};
