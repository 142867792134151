import classNames from 'classnames';
import React from 'react';

interface Props {
  src?: string;
  alt?: string;
  className?: string;
}

export const MaybeImage: React.FC<Props> = ({ src, alt, className }) =>
  src ? <img {...{ src, alt, className }} /> : <div className={classNames('bg-shade-light', className)} />;
