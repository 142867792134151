import { translations } from '@binhatch/locale';
import classNames from 'classnames';
import { OrderStatus } from 'flexinet-api';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const map: Record<OrderStatus | 'unknown', string> = {
  [OrderStatus.Pending]: 'bg-blue-200 text-blue-700',
  [OrderStatus.Completed]: 'bg-success text-success-light',
  [OrderStatus.Cancelled]: 'bg-error text-error-light',
  unknown: 'bg-yellow-400 text-yellow-700'
};

interface Props {
  status?: OrderStatus;
}

export const OrderStatusTag: React.FC<Props> = ({ status }) => (
  <div
    className={classNames(
      'inline-flex rounded px-2 py-1 text-xs font-semibold uppercase tracking-wide',
      map[status ?? 'unknown']
    )}
  >
    <FormattedMessage id={translations.enum.orderStatus[status ?? 'unknown']} />
  </div>
);
