import React from 'react';
import { FormattedNumber } from 'react-intl';

import { Coin } from './Coin';

interface Props {
  className?: string;
  value?: number;
}

export const CoinValue: React.FC<React.PropsWithChildren<Props>> = ({ className, value = 0 }) => (
  <Coin {...{ className }}>
    <FormattedNumber style="decimal" {...{ value }} />
  </Coin>
);
