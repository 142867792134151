import { translations } from '@binhatch/locale';
import { LastPromotionClientProgress } from '@binhatch/utility';
import classNames from 'classnames';
import { Promotion, UserPromotion } from 'flexinet-api';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { ProgressBar } from '../ProgressBar';
import { PromotionTargetProgressNumbers } from './PromotionTargetProgressNumbers';

interface Props {
  name?: React.ReactNode;
  promotion: Promotion | UserPromotion;
  progress: LastPromotionClientProgress;
  className?: string;
}

export const PromotionTargetProgress: React.FC<React.PropsWithChildren<Props>> = ({
  name,
  promotion,
  progress,
  className,
  children
}) => (
  <div className={classNames('flex flex-col gap-1', className)}>
    <div className="flex flex-wrap items-end gap-x-4 whitespace-nowrap">
      <div className="font-semibold">
        {name ?? (
          <FormattedMessage
            id={translations.components.promotionTargetProgress.name}
            values={{ index: progress.index + 1 }}
          />
        )}
      </div>

      <div className="flex flex-1 justify-between gap-x-4">
        <PromotionTargetProgressNumbers {...{ promotion, progress }} />

        {children}
      </div>
    </div>

    <ProgressBar
      barClassName={
        progress.percent < 0.5
          ? 'bg-error'
          : progress.percent < 0.75
          ? 'bg-brand'
          : progress.percent < 1
          ? 'bg-brand'
          : 'bg-success'
      }
      progress={progress.percent}
    />
  </div>
);
