import { useBalance } from '@/hooks/useBalance';
import { translations } from '@binhatch/locale';
import { Card, ClientAvatar, CoinValue, Tab, TabList, UserAvatar } from '@binhatch/ui';
import { BeneficiaryKind, UserRole } from 'flexinet-api';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Outlet } from 'react-router';

import { urls } from '@/utils/url';

import { Auth } from '@/containers/useAuth';
import { getUserName } from '@binhatch/utility';

export const ProfilePage: React.FC = () => {
  const { context } = Auth.useContainer();
  const { balances } = useBalance();

  return (
    <main className="flex flex-col gap-8">
      <Card className="flex flex-col gap-8 xl:flex-row">
        <div className="flex flex-1 flex-col flex-wrap gap-4 xl:flex-row xl:flex-nowrap xl:items-center xl:justify-between">
          <div className="flex gap-4">
            <UserAvatar className="flex-shrink-0" />

            <div>
              <div className="font-semibold">{!!context && getUserName(context.user)}</div>
              <div>{context?.user.details.email}</div>
            </div>
          </div>

          <div className="flex flex-1 items-center justify-between xl:flex-col xl:items-end">
            <FormattedMessage id={translations.enum.balance[BeneficiaryKind.User]} />
            <CoinValue value={balances?.[BeneficiaryKind.User]?.amount ?? 0} />
          </div>
        </div>

        <div className="bg-shade h-px flex-shrink-0 xl:h-full xl:w-px" />

        <div className="flex flex-1 flex-col flex-wrap gap-4 xl:flex-row xl:flex-nowrap xl:items-center xl:justify-between">
          <div className="flex gap-4">
            <ClientAvatar className="flex-shrink-0" />

            <div>
              <div className="font-semibold">{context?.client?.name}</div>
              <div>{!!context && <FormattedMessage id={translations.enum.userRole[context.user.role]} />}</div>
            </div>
          </div>

          {context?.user.role === UserRole.CustomerAdmin && (
            <div className="flex flex-1 items-center justify-between xl:flex-col xl:items-end">
              <FormattedMessage id={translations.enum.balance[BeneficiaryKind.Client]} />
              <CoinValue value={balances?.[BeneficiaryKind.Client]?.amount ?? 0} />
            </div>
          )}
        </div>
      </Card>

      {context?.user.role === UserRole.CustomerAdmin && (
        <TabList>
          <Tab end to={urls.profile.root}>
            <FormattedMessage id={translations.tabs.profileTransactions[BeneficiaryKind.Client]} />
          </Tab>

          <Tab end to={urls.profile.transactions.user}>
            <FormattedMessage id={translations.tabs.profileTransactions[BeneficiaryKind.User]} />
          </Tab>
        </TabList>
      )}

      <Outlet />
    </main>
  );
};
