import classNames from 'classnames';
import React from 'react';

interface ProgressBarContainerProps {
  color?: string;
  className?: string;
}

export const ProgressBarContainer: React.FC<React.PropsWithChildren<ProgressBarContainerProps>> = ({
  color,
  className,
  ...props
}) => (
  <div
    {...props}
    className={classNames('flex h-2 items-center overflow-hidden rounded-full', className, color ?? 'bg-shade')}
  />
);

interface ProgressBarProps {
  progress: number;
  barClassName?: string;
  className?: string;
}

export const ProgressBar: React.FC<ProgressBarProps> = ({ progress, barClassName, ...props }) => (
  <ProgressBarContainer {...props}>
    <div
      className={classNames('h-full rounded', barClassName)}
      style={{ width: `${Math.max(Math.min(progress * 100, 100), 0)}%` }}
    />
  </ProgressBarContainer>
);

interface ProgressBarsProps extends ProgressBarContainerProps {
  bars: { progress: number; className?: string }[];
}

export const ProgressBars: React.FC<ProgressBarsProps> = ({ bars, ...props }) => (
  <ProgressBarContainer {...props}>
    {bars
      .filter((p) => p.progress > 0)
      .map(({ progress, className }, index, array) => (
        <div
          className={classNames(
            'h-full',
            { 'rounded-l': index === 0, 'rounded-r': index === array.length - 1 },
            className
          )}
          key={index}
          style={{ width: `${Math.max(Math.min(progress * 100, 100), 0)}%` }}
        />
      ))}
  </ProgressBarContainer>
);
