import { translations } from '@binhatch/locale';
import { Period, Promotion, UserPromotion } from 'flexinet-api';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import classNames from 'classnames';
import { DayCount } from './DayCount';
import { PromotionArticles } from './PromotionArticle';
import { PromotionImage } from './PromotionImage';
import { PromotionPeriod } from './PromotionPeriod';

interface Props {
  promotion: Promotion | UserPromotion;
  days: number;
  period?: Period;
  className?: string;
}

export const PromotionItemContainer: React.FC<React.PropsWithChildren<{ className?: string }>> = (props) => (
  <div {...props} className={classNames('w-full overflow-hidden rounded-lg bg-white shadow', props.className)} />
);

export const PromotionItem: React.FC<Props> = ({ promotion, period, days, className }) => (
  <div className={classNames('flex flex-1', className)}>
    <PromotionImage className="mr-4 w-20 flex-shrink-0" image={promotion.imageURL} logo={promotion.brandLogoURL} />

    <div className="flex flex-1 justify-between gap-4">
      <div className="flex flex-1 flex-col gap-1">
        <div className="font-semibold">{promotion.name}</div>

        <PromotionArticles tags={promotion.tags} />

        <div className="line-clamp-2 text-sm">{promotion.description}</div>
      </div>

      <div className="flex flex-col items-end gap-1 text-sm">
        <DayCount count={days} />

        {!!period && (
          <div>
            <PromotionPeriod endAt={new Date(period.endAt)} startAt={new Date(period.startAt)} />
          </div>
        )}

        {!!promotion.repetition && (
          <div>
            <FormattedMessage id={translations.enum.repetition[promotion.repetition]} />
          </div>
        )}
      </div>
    </div>
  </div>
);
