import classNames from 'classnames';
import React from 'react';

import { MaybeImage } from '../MaybeImage';

interface Props {
  image?: string;
  logo?: string;
  className?: string;
}

export const PromotionImage: React.FC<Props> = ({ logo, image, className }) => (
  <div className={classNames('relative', className)}>
    <MaybeImage className="max-h-16 w-full rounded-t object-cover object-center" src={image} />
    <MaybeImage className="max-h-8 w-full rounded-b object-cover object-center" src={logo} />
  </div>
);
