import { useRemoteData } from '@binhatch/hooks';
import { BeneficiaryKind } from 'flexinet-api';
import React from 'react';
import { useLocalStorage } from 'react-use';

import { balanceApi } from '@/integrations/api';

import { Auth } from '@/containers/useAuth';

export const useBalance = () => {
  const { context } = Auth.useContainer();
  const [type = BeneficiaryKind.User, setType] = useLocalStorage<BeneficiaryKind>('balance-type');

  const balance = useRemoteData({ key: `useBalance`, userId: context?.user.id, skip: !context }, async ({ userId }) => {
    if (!userId) throw new Error('No user in context.');

    const { balances, clientBalance } = await balanceApi.getUserBalance([userId]).then((r) => r.data);

    return {
      [BeneficiaryKind.User]: balances[0],
      [BeneficiaryKind.Client]: clientBalance
    };
  });

  return React.useMemo(
    () => ({
      type:
        type === BeneficiaryKind.Client && balance.data && !balance.data?.[BeneficiaryKind.Client]
          ? BeneficiaryKind.User
          : type,
      setType,
      balances: balance.data,
      reload: () => balance.mutate()
    }),
    [type, setType, balance]
  );
};
