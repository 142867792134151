import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import * as React from 'react';
import { createAutoCorrectedDatePipe } from 'text-mask-addons';

import { getValueFromEvent, MaskedInput } from './MaskedInput';

dayjs.extend(customParseFormat);

type PotentialDate = string | Date | null | undefined;

interface Props {
  value?: PotentialDate;
  mask: string;
  onChange(value?: PotentialDate): void;
}

const parsePotentialDate = <T extends PotentialDate>(
  value: PotentialDate,
  format: string,
  callback: (date: dayjs.Dayjs) => T
) => {
  if (!value) return value;

  if (value instanceof Date) return callback(dayjs(value));

  const date = dayjs(value, format, true);

  if (!date.isValid()) return value;

  return callback(date);
};

// text-mask is using dd/mm/yyyy HH:MM while dayjs is using DD/MM/YYYY HH:mm
const flipCasing = (mask: string) => {
  return mask.replace(/[a-zA-Z]/g, (letter) => {
    if (letter === 'H') return letter;

    const lowercased = letter.toLowerCase();

    if (lowercased !== letter) return lowercased;

    return letter.toUpperCase();
  });
};

export const DateInput = React.forwardRef(function DateInput(props: Props, ref: React.Ref<any>) {
  const { mask, pipe } = React.useMemo(() => {
    const pipe = createAutoCorrectedDatePipe(flipCasing(props.mask));
    const mask = props.mask.split('').map((letter) => (/[a-zA-Z]/.test(letter) ? /\d/ : letter));

    return { pipe, mask };
  }, [props]);

  const value = React.useMemo(
    () => parsePotentialDate(props.value, props.mask, (date) => date.format(props.mask)),
    [props.value, props.mask]
  );

  const onChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = getValueFromEvent(event);

      return props.onChange?.(parsePotentialDate(value, props.mask, (date) => date.toDate()));
    },
    [props]
  );

  return <MaskedInput {...props} {...{ ref, value, mask, pipe, onChange }} />;
});
