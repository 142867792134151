import classNames from 'classnames';
import React from 'react';

import { As, AsComponent, AsProps } from '../As';

const styles = {
  primary:
    'border-brand bg-brand text-white :not:disabled:hover:bg-brand/90 disabled:bg-gray-200 disabled:border-transparent disabled:text-gray-500',
  secondary: 'border-transparent bg-white :not:disabled:hover:bg-[#CCCCCC10]',
  outline: 'bg-white border-border/50 :not:disabled:hover:bg-[#CCCCCC10]',
  transparent: 'border-transparent bg-transparent text-brand :not:disabled:hover:bg-slate-50',
  danger: 'border-red-600 bg-red-600 :not:disabled:hover:bg-red-500 text-white'
};

export type Appearance = keyof typeof styles;

interface Props {
  appearance?: Appearance;
}

export const Button = React.forwardRef(
  <T extends AsComponent>(
    // @ts-ignore
    { as = 'button', appearance, className, ...props }: Props & AsProps<T>,
    ref: React.Ref<HTMLElement>
  ) => (
    <As
      {...{ as, ref }}
      className={classNames(
        'inline-flex items-center justify-center rounded border border-solid text-sm font-semibold',
        !!appearance && styles[appearance],
        className
      )}
      {...props}
    />
  )
);
