import { useRemoteData } from '@binhatch/hooks';
import { translations } from '@binhatch/locale';
import { Card, Checkbox, LoadingState } from '@binhatch/ui';
import { NotificationChannel, NotificationKind } from 'flexinet-api';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { notificationApi } from '@/integrations/api';

export const SettingsNotificationsPage: React.FC = () => {
  const notifications = useRemoteData({ key: 'useNotificationPreferences' }, () =>
    notificationApi.getUserPreferences().then((r) => r.data.notificationPreferences)
  );

  return (
    <Card className="flex">
      <LoadingState loading={notifications.isLoading}>
        <div className="grid gap-x-16 gap-y-4 lg:grid-cols-2">
          {[
            NotificationKind.PromotionStart,
            NotificationKind.PromotionHalfTime,
            NotificationKind.PromotionEnd,
            NotificationKind.NewProduct,
            NotificationKind.ExpiryReminder,
            NotificationKind.OrderConfirmation,
            NotificationKind.ProductRequestResponse
          ].map((notification) => (
            <React.Fragment key={notification}>
              <div className="font-semibold">
                <FormattedMessage id={translations.enum.notificationKind[notification]} />
              </div>

              <ul className="flex gap-8">
                {[NotificationChannel.Email, NotificationChannel.Sms]
                  .map((channel) => ({
                    channel,
                    checked: !!notifications.data?.find((n) => n.kind === notification && n.channel === channel)
                      ?.enabled
                  }))
                  .map(({ channel, checked }) => (
                    <li key={channel}>
                      <Checkbox
                        id={`notification-${notification}-${channel}`}
                        name={`notification-${notification}-${channel}`}
                        type="checkbox"
                        {...{ checked }}
                        onChange={() =>
                          notificationApi
                            .updateUserPreferences({
                              notificationPreferences: [{ channel, kind: notification, enabled: !checked }]
                            })
                            .then(() => notifications.mutate())
                        }
                      >
                        <FormattedMessage id={translations.enum.notificationChannel[channel]} />
                      </Checkbox>
                    </li>
                  ))}
              </ul>
            </React.Fragment>
          ))}
        </div>
      </LoadingState>
    </Card>
  );
};
