import { useAsyncFn } from '@binhatch/hooks';
import React from 'react';

import { LoadingButton } from './LoadingButton';

type LoadingButtonProps = React.ComponentProps<typeof LoadingButton>;

interface Props extends Omit<LoadingButtonProps, 'loading' | 'children' | 'onClick'> {
  children: ((loaded: boolean) => React.ReactNode) | React.ReactNode;
  once?: boolean;
  onClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): Promise<unknown>;
}

export const AsyncButton = React.forwardRef(function AsyncButton({ once, ...props }: Props, ref: React.Ref<any>) {
  const [{ value: loaded = false, loading }, onClick] = useAsyncFn(
    async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      await props.onClick(event);
      return true;
    },
    [props.onClick]
  );

  const disabled = props.disabled || (!!loaded && !!once);
  const children = typeof props.children === 'function' ? props.children(loaded) : props.children;

  return <LoadingButton {...props} {...{ ref, loading, disabled, children, onClick }} />;
});
