import { Pagination, TransactionList } from '@binhatch/ui';
import React from 'react';

import { transactionApi } from '@/integrations/api';

import { QueryParamConfig, decodeSingleQueryParam, useQueryParams, useRemoteData } from '@binhatch/hooks';

import { Auth } from '@/containers/useAuth';
import { transactionUrlMapper } from '@/utils/transactionMapper';

const page: QueryParamConfig<string | undefined> = {
  decode: (value) => decodeSingleQueryParam(value, undefined),
  encode: (value) => value
};

const config = { page };

export const ClientBalanceHistoryPage: React.FC = () => {
  const { context } = Auth.useContainer();
  const [query, updateQuery] = useQueryParams({ config });

  const transactions = useRemoteData(
    { key: `useTransactions`, userId: context?.user.id, clientId: context?.client?.id, nextToken: query.page },
    async ({ clientId, nextToken }) => {
      if (!clientId) return;

      return transactionApi.listUserTransactions(nextToken).then((r) => r.data);
    }
  );

  return (
    <div className="space-y-6">
      <TransactionList
        loading={transactions.isLoading || transactions.isValidating}
        transactions={transactions.data?.transactions}
        urlMapper={transactionUrlMapper}
      />

      <Pagination
        hasNext={!!transactions.data?.nextToken}
        // hasPrevious={!!tales.data?.previousToken}
        onNext={() => updateQuery({ page: transactions.data?.nextToken })}
        // onPrevious={() => updateQuery({ page: tales.data?.previousToken })}
      />
    </div>
  );
};
