import classNames from 'classnames';
import React from 'react';
import { NavLink } from 'react-router-dom';

interface NavigationItemProps {
  className?: string;
}

export const NavigationItem: React.FC<React.PropsWithChildren<NavigationItemProps>> = ({ className, ...props }) => (
  <div {...props} className={classNames('block border-l-4 px-4 py-4 transition-colors', className)} />
);

interface NavigationLinkProps {
  to: string;
  className?: string;
  disabled?: boolean;
}

export const NavigationLink: React.FC<React.PropsWithChildren<NavigationLinkProps>> = ({
  to,
  className,
  disabled,
  ...props
}) => (
  <NavLink
    {...props}
    state={{ from: to }}
    className={({ isActive }) =>
      classNames(
        'block border-l-4 px-4 py-4 font-medium transition-colors',
        disabled ? 'pointer-events-none text-gray-400' : isActive ? 'border-l-brand bg-white' : 'hover:bg-white/40',
        className
      )
    }
    to={disabled ? '#' : to}
  />
);

interface NavigationLinkWithIconProps {
  icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  className?: string;
  iconClassName?: string;
}

export const NavigationContentWithIcon: React.FC<React.PropsWithChildren<NavigationLinkWithIconProps>> = ({
  icon: Icon,
  className,
  iconClassName,
  children,
  ...props
}) => {
  return (
    <div {...props} className={classNames('flex items-center', className)}>
      <Icon className={classNames('mr-3 h-6 w-6 flex-shrink-0', iconClassName)} />
      {children}
    </div>
  );
};

interface NavigationButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {}

export const NavigationButton: React.FC<React.PropsWithChildren<NavigationButtonProps>> = ({ className, ...props }) => (
  <button
    {...props}
    className={classNames(
      'w-full rounded border-l-4 py-4 pl-4 pr-8 font-medium transition-colors hover:bg-white/40',
      className
    )}
  />
);
