import classNames from 'classnames';
import React from 'react';

import { As, AsComponent, AsProps } from '../As';

export type InputProps<T extends AsComponent> = AsProps<T> & {
  invalid?: boolean;
};

export const Input = React.forwardRef(function Input<T extends AsComponent = 'input'>(
  // @ts-ignore
  { as = 'input', invalid, ...props }: React.PropsWithChildren<InputProps<T>>,
  ref: React.Ref<any>
) {
  return (
    <As
      {...{ as, ref }}
      {...props}
      className={classNames(
        'form-input block h-10 rounded border border-solid p-2 text-sm placeholder:text-[#7A7A7A]',
        { 'border-border/50': !invalid, 'border-red-500': invalid },
        props.disabled && 'bg-[#F6F6F6]',
        props.className
      )}
    />
  );
});
