import classNames from 'classnames';
import React from 'react';

import { AsComponent } from '../As';

import { InputProps } from './Input';
import { Item } from './Select';

const ButtonGroupRadio = React.forwardRef(function ButtonGroupRadio(
  { invalid, className, children, ...props }: React.PropsWithChildren<InputProps<'input'>>,
  ref: React.Ref<any>
) {
  return (
    <div className="group">
      <input {...props} {...{ ref }} className="peer m-0 block h-0 w-0 opacity-0" />

      <label
        className={classNames(
          '-ml-px inline-flex h-full cursor-pointer items-center justify-center rounded px-2 text-sm font-semibold focus:z-10 group-first:mx-0 peer-disabled:cursor-default',
          props.checked ? 'border-brand bg-brand text-white' : 'border-border hover:bg-shade'
        )}
        htmlFor={props.id}
        id={props.id}
      >
        {children}
      </label>
    </div>
  );
});

type Props<T extends AsComponent> = Omit<InputProps<T>, 'items'> & {
  items: Item<string>[];
};

export const ButtonRadio = React.forwardRef(function ButtonRadioInput<T extends AsComponent>(
  { id, items, value, name, className, onChange, ...props }: Props<T>,
  ref: React.Ref<any>
) {
  return (
    <div
      className={classNames('bg-shade-light border-shade-light isolate inline-flex gap-2 rounded border-4', className)}
    >
      {items.map((item) => (
        <ButtonGroupRadio
          {...{ ref }}
          id={`${id}-${item.value}`}
          key={item.value}
          {...props}
          {...{ name }}
          checked={value === item.value}
          value={item.value}
          onChange={() => onChange(item.value)}
        >
          {item.name}
        </ButtonGroupRadio>
      ))}
    </div>
  );
});
