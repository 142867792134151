import { translations } from '@binhatch/locale';
import {
  Button,
  CoinValue,
  DynamicBackButton,
  LoadingButton,
  LoadingState,
  PageLoaderHeading,
  ProductImage
} from '@binhatch/ui';
import { CheckIcon } from '@heroicons/react/20/solid';
import { TrashIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, Navigate } from 'react-router-dom';
import { useAsyncFn } from 'react-use';

import { orderApi } from '@/integrations/api';
import { urls } from '@/utils/url';

import { Cart } from '@/containers/useCart';

import { useBalance } from '@/hooks/useBalance';

export const CartPage: React.FC = () => {
  const cart = Cart.useContainer();
  const { type, balances, reload } = useBalance();

  const balanceId = balances?.[type]?.id;

  const [{ loading, value }, purchase] = useAsyncFn(async () => {
    if (!balanceId) throw new Error(`Balance does not exist.`);

    const items = cart.items.map((item) => ({ qty: item.quantity, productId: item.product.id }));

    await orderApi.createOrder({ balanceID: balanceId, items });

    await cart.clear();

    reload().catch(() => void 0);

    return true;
  }, [balanceId, reload, cart.items]);

  if (!cart.loading && cart.items.length === 0 && !value) return <Navigate to={urls.shop.root} />;

  return (
    <main className="flex flex-1 flex-col space-y-6">
      {!value ? (
        <React.Fragment>
          <div>
            <DynamicBackButton />

            <PageLoaderHeading loading={cart.loading}>
              <FormattedMessage id={translations.pages.cart.title} />
            </PageLoaderHeading>
          </div>

          <div className="rounded-lg bg-white p-8">
            <LoadingState loading={cart.loading}>
              <ul className="grid gap-4">
                {cart.items.map(({ product, quantity }) => (
                  <li
                    className="relative flex flex-col justify-between gap-4 overflow-hidden md:flex-row md:items-center"
                    key={product.id}
                  >
                    <div className="flex items-center gap-4">
                      <ProductImage className="w-16 flex-shrink-0" square src={product.media[0]} />

                      <div className="space-y-2">
                        <div className="font-semibold">{product.name}</div>

                        <Button appearance="secondary" onClick={() => cart.update(product, 0)}>
                          <TrashIcon className="mr-2 h-4 w-4 stroke-2" />
                          <FormattedMessage id={translations.buttons.delete} />
                        </Button>
                      </div>
                    </div>

                    <div className="flex items-center justify-end whitespace-nowrap">
                      <div className="">{quantity} &times;&nbsp;</div>

                      <CoinValue value={product.value} />
                    </div>
                  </li>
                ))}
              </ul>

              <div className="border-shade mt-4 flex items-center justify-end gap-4 border-t pt-4 font-semibold">
                <FormattedMessage id={translations.pages.shop.cart.total} />

                <CoinValue value={cart.total} />
              </div>
            </LoadingState>
          </div>

          <div className="flex sm:justify-end">
            <LoadingButton
              appearance="primary"
              className="h-14 w-full px-4 sm:w-auto"
              {...{ loading }}
              disabled={!!value}
              onClick={() => purchase()}
            >
              <FormattedMessage id={translations.pages.order.purchase} />
            </LoadingButton>
          </div>
        </React.Fragment>
      ) : (
        <div className="flex flex-1 flex-col items-center justify-center gap-4 text-center">
          <div className="bg-brand flex h-20 w-20 items-center justify-center rounded-full text-white md:h-36 md:w-36">
            <CheckIcon className="h-10 w-10 md:h-20 md:w-20" />
          </div>

          <div className="text-xl font-semibold">
            <FormattedMessage id={translations.pages.order.purchased.title} />
          </div>

          <div>
            <FormattedMessage id={translations.pages.order.purchased.message} />
          </div>

          <Button appearance="secondary" as={Link} className="h-14 px-4" state={{ from: 1 }} to={urls.shop.root}>
            <FormattedMessage id={translations.pages.order.back} />
          </Button>
        </div>
      )}
    </main>
  );
};
