import classNames from 'classnames';
import React from 'react';

import { ArchiveBoxXMarkIcon } from '@heroicons/react/24/outline';

interface Props {
  className?: string;
}

export const EmptyState: React.FC<React.PropsWithChildren<Props>> = ({ className, children }) => (
  <div
    className={classNames(
      'min-h-80 flex w-full flex-col items-center justify-center gap-2 text-sm font-semibold',
      className
    )}
  >
    <ArchiveBoxXMarkIcon className="h-20 w-20 stroke-1" />
    {children}
  </div>
);
