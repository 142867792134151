import { useRemoteData, useStrictParams } from '@binhatch/hooks';
import { translations } from '@binhatch/locale';
import {
  AsyncButton,
  Card,
  CoinValue,
  DynamicBackButton,
  LoadingState,
  PageHeading,
  ProductImage,
  Skeleton,
  Stepper
} from '@binhatch/ui';
import { Product, ProductAvailability } from 'flexinet-api';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { productApi } from '@/integrations/api';

import { Cart } from '@/containers/useCart';

export const ProductDetailPage: React.FC = () => {
  const { productId } = useStrictParams<{ productId: string }>();

  const [image, setImage] = React.useState<string>();
  const [quantity, setQuantity] = React.useState(1);

  const cart = Cart.useContainer();

  const cartQuantity = (cart.items.find((i) => i.productId === productId)?.quantity ?? 0) + quantity;

  const product = useRemoteData(
    { key: `useProduct`, productId: productId, skip: !productId },
    async ({ productId }): Promise<Product> => productApi.getUserProductById(productId!).then((r) => r.data)
  );

  return (
    <main>
      <LoadingState loading={product.isLoading || product.isValidating}>
        <div className="flex flex-col items-start gap-4">
          <DynamicBackButton />

          <Card className="flex w-full flex-col flex-wrap gap-8 lg:flex-row">
            <div className="flex gap-4 lg:w-64 lg:flex-col">
              <a className="block w-full" href={image ?? product.data?.media[0]} rel="noreferrer" target="_blank">
                <ProductImage src={image ?? product.data?.media[0]} />
              </a>

              <ul className="flex w-1/4 flex-col gap-4 lg:grid lg:w-auto lg:grid-cols-3">
                {(product.data?.media.slice(0, 3) ?? Array.from({ length: 3 })).map((src, index) => (
                  <li key={index}>
                    <button className="block w-full" onClick={() => setImage(src)}>
                      <ProductImage {...{ src }} />
                    </button>
                  </li>
                ))}
              </ul>
            </div>

            <div className="flex flex-1 flex-col gap-4">
              <PageHeading title={product.data?.name} />

              <div className="grid gap-4 lg:max-w-3xl xl:grid-cols-2 2xl:grid-cols-3">
                <div className="col-span-2 space-y-1 xl:col-span-1">
                  <div className="font-semibold">
                    <FormattedMessage id={translations.pages.productDetail.sku} />
                  </div>

                  <Skeleton className="mb-2 w-32" size="h-5" visible={!product.data}>
                    <div className="break-words">{product.data?.productCode}</div>
                  </Skeleton>
                </div>

                <div className="space-y-1">
                  <div className="font-semibold">
                    <FormattedMessage id={translations.pages.productDetail.category} />
                  </div>

                  <Skeleton className="mb-2 w-32" size="h-5" visible={!product.data}>
                    <div className="break-words">{product.data?.category}</div>
                  </Skeleton>
                </div>

                <div className="space-y-1">
                  <div className="font-semibold">
                    <FormattedMessage id={translations.fields.productQuantity.label} />
                  </div>

                  <Skeleton className="mb-2 w-32" size="h-5" visible={!product.data}>
                    <div className="break-words">{product.data?.quantity}</div>
                  </Skeleton>
                </div>
              </div>

              {!!product.data && (
                <React.Fragment>
                  {product.data.availability === ProductAvailability.Available ? (
                    <React.Fragment>
                      <CoinValue value={product.data?.value ?? 0} />

                      <div className="flex gap-4">
                        <Stepper className="h-14" min={1} value={quantity} onChange={setQuantity} />

                        <AsyncButton
                          appearance="primary"
                          className="h-14 px-8"
                          disabled={product.data.quantity < cartQuantity}
                          onClick={() => {
                            if (!product.data) return;
                            cart.toggle(true);
                            cart.adjust(product.data, quantity);
                          }}
                        >
                          <FormattedMessage id={translations.buttons.addToCart} />
                        </AsyncButton>
                      </div>
                    </React.Fragment>
                  ) : (
                    <div className="text-error">
                      <FormattedMessage id={translations.pages.productDetail.unavailable} />
                    </div>
                  )}
                </React.Fragment>
              )}
            </div>

            <div className="w-full space-y-1">
              <div className="font-semibold">
                <FormattedMessage id={translations.pages.productDetail.description} />
              </div>
              <Skeleton className="mb-2 inline-flex w-full" size="h-5" visible={!product.data} />
              <Skeleton className="mb-2 inline-flex w-full" size="h-5" visible={!product.data} />
              <Skeleton className="mb-2 inline-flex w-32" size="h-5" visible={!product.data} />
              {product.data?.description?.split(/\r?\n/).map((line, index) => <div key={index}>{line || '\xa0'}</div>)}
            </div>
          </Card>
        </div>
      </LoadingState>
    </main>
  );
};
