import { translations } from '@binhatch/locale';
import { LastPromotionClientProgress, getCurrency } from '@binhatch/utility';
import classNames from 'classnames';
import { Promotion, UserPromotion } from 'flexinet-api';
import React from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import { TargetValue } from './TargetValue';

interface Props {
  promotion: Promotion | UserPromotion;
  progress: LastPromotionClientProgress;
  className?: string;
}

export const PromotionTargetProgressNumbers: React.FC<React.PropsWithChildren<Props>> = ({
  promotion,
  progress,
  className
}) => (
  <div className={classNames('flex items-end gap-4', className)}>
    <div>
      <FormattedNumber style="percent" value={progress.percent} />
    </div>

    <div>
      <TargetValue currency={getCurrency(promotion)} unit={promotion.target.targetMu} value={progress.value} minimal />

      {' / '}

      <TargetValue
        currency={getCurrency(promotion)}
        unit={promotion.target.targetMu}
        value={parseFloat(progress.level.value)}
      />
    </div>

    {parseFloat(progress.level.value) - progress.value > 0 && (
      <div className="hidden sm:block md:hidden lg:block xl:hidden 2xl:block">
        (
        <FormattedMessage
          id={translations.components.promotionTargetProgress.left}
          values={{
            value: (
              <TargetValue
                currency={getCurrency(promotion)}
                unit={promotion.target.targetMu}
                value={parseFloat(progress.level.value) - progress.value}
              />
            )
          }}
        />
        )
      </div>
    )}
  </div>
);
