import { translations } from '@binhatch/locale';
import { AsyncButton, Card, Modal, PageHeading, useModal } from '@binhatch/ui';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocalStorage } from 'react-use';

import { Tenant } from '@/containers/useTenant';

import { Logo } from '@/components/Logo';
import { Auth } from '@/containers/useAuth';
import { CookieModal } from '@/modals/CookieModal';

export const LoginPage: React.FC<React.PropsWithChildren> = () => {
  const { data } = Tenant.useContainer();
  const { login } = Auth.useContainer();
  const [accepted, setAccepted] = useLocalStorage<boolean>('legal', false);

  const cookieModal = useModal(CookieModal);

  React.useEffect(() => {
    if (accepted || !data?.cookiesURL) return;

    cookieModal
      .open({})
      .then(() => setAccepted(true))
      .catch(() => void 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, accepted, setAccepted]);

  return (
    <React.Fragment>
      <div className="flex flex-1 flex-col items-center justify-center p-4">
        <div className="flex w-full max-w-md flex-col gap-4">
          <div className="flex justify-center p-4">
            <Logo className="inline-flex" />
          </div>

          <Card className="flex flex-col gap-4">
            <PageHeading title={<FormattedMessage id={translations.pages.login.title} />} />

            <div className="">
              <FormattedMessage id={translations.pages.login.message} />
            </div>

            <AsyncButton appearance="primary" className="h-10 w-full md:h-14" onClick={() => login()}>
              <FormattedMessage id={translations.pages.login.cta} />
            </AsyncButton>
          </Card>

          <div className="text-center text-sm">
            <FormattedMessage
              id={translations.pages.login.legal}
              values={{
                tos: (children) => (
                  <a className="text-brand hover:underline" href={data?.tcURL} rel="noreferrer" target="_blank">
                    {children}
                  </a>
                ),
                pp: (children) => (
                  <a className="text-brand hover:underline" href={data?.ppURL} rel="noreferrer" target="_blank">
                    {children}
                  </a>
                ),
                cp:
                  !!data?.cookiesURL &&
                  ((children) => (
                    <a className="text-brand hover:underline" href={data?.cookiesURL} rel="noreferrer" target="_blank">
                      {children}
                    </a>
                  ))
              }}
            />
          </div>
        </div>
      </div>

      <Modal {...cookieModal.props} important />
    </React.Fragment>
  );
};
