import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import React from 'react';

import { As, AsComponent } from '../As';

import { Input, InputProps } from './Input';

export type SearchProps<T extends AsComponent> = InputProps<T> & {
  id?: string;
  value?: string;
  onChange(value: string): void;
};

export const SearchInput = React.forwardRef(function SearchInput<T extends AsComponent = typeof Input>(
  //@ts-ignore
  { as = Input, value: initialValue, className, onChange, ...props }: Props<T>,
  ref: React.Ref<any>
) {
  const [value, setValue] = React.useState(() => initialValue ?? '');

  React.useEffect(() => setValue(initialValue ?? ''), [initialValue]);

  return (
    <label className={classNames('relative', className)}>
      <div className="pointer-events-none absolute left-0 top-0 flex h-10 w-10 items-center justify-center">
        <MagnifyingGlassIcon className="h-5 w-5" />
      </div>

      <As
        {...{ ref, as, value }}
        {...props}
        className={classNames('w-full pl-10', { 'pr-10': value })}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setValue(event.target.value)}
        onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
          if (event.key !== 'Enter') return;
          setValue(value);
          onChange(value);
        }}
      />

      {value.length > 0 && (
        <button
          className="absolute right-0 top-1/2 flex h-10 w-10 -translate-y-1/2 items-center justify-center"
          type="button"
        >
          <XMarkIcon
            className="h-5 w-5"
            onClick={() => {
              setValue('');
              onChange('');
            }}
          />
        </button>
      )}
    </label>
  );
});
