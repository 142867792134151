import { translations } from '@binhatch/locale';
import { getUserName } from '@binhatch/utility';
import { Order, UserOrder } from 'flexinet-api';
import React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Card } from '../Card';
import { CoinValue } from '../CoinValue';
import { LoadingState } from '../LoadingState';
import { ProductImage } from '../ProductImage';

import { ClientAvatar, UserAvatar } from '../avatars';
import { ClientWithUrl } from '../clients';
import { UserWithUrl } from '../users';

interface Props {
  order?: Order | UserOrder;
  user?: UserWithUrl;
  client?: ClientWithUrl;
  loading: boolean;
  getProductUrl(id: string): string;
}

export const OrderDetail: React.FC<Props> = ({ loading, order, user, client, getProductUrl }) => (
  <LoadingState {...{ loading }}>
    <div className="space-y-6">
      <Card className="flex flex-col justify-between lg:flex-row">
        <div className="flex gap-8">
          {!!user && (
            <Link className="flex gap-4" state={{ from: 1 }} to={user.url ?? ''}>
              <UserAvatar />

              <div>
                <div>{getUserName(user.user)}</div>
                <div>{user.user.details.email}</div>
              </div>
            </Link>
          )}

          {!!client && (
            <Link className="flex items-center gap-4" state={{ from: 1 }} to={client.url}>
              <ClientAvatar />

              <div>{client.client.name}</div>
            </Link>
          )}
        </div>

        <div className="flex flex-col items-end gap-2">
          <div className="flex items-center gap-2">
            <FormattedMessage
              id={translations.components.orderDetail.balance}
              values={{ type: order?.balance?.beneficiary.kind }}
            />

            <CoinValue value={order?.items.reduce((total, item) => total + item.product.value * item.qty, 0) ?? 0} />
          </div>

          <FormattedDate dateStyle="long" timeStyle="short" value={order?.createdAt} />
        </div>
      </Card>

      <Card className="space-y-4">
        <div className="font-semibold">
          <FormattedMessage id={translations.components.orderDetail.products} />
        </div>

        <ul className="divide-shade grid gap-8">
          {order?.items.map((item, index) => (
            <li className="flex flex-col justify-between gap-4 lg:flex-row" key={index}>
              <Link className="flex items-center gap-4" state={{ from: 1 }} to={getProductUrl(item.product.id)}>
                <ProductImage className="w-16" square src={item.product.media[0]} />

                <div>
                  <div>{item.product.name}</div>
                  <div>{item.product.productCode}</div>
                </div>
              </Link>

              <div className="flex items-center justify-end">
                {item.qty} &times;&nbsp;
                <CoinValue value={item.product.value} />
              </div>
            </li>
          ))}
        </ul>
      </Card>
    </div>
  </LoadingState>
);
