import { Locale as ApiLocale } from 'flexinet-api';
import { Locale } from './locales';

export const fromApiLocale = (locale: ApiLocale): Locale => {
  switch (locale) {
    case ApiLocale.EnUs:
      return Locale.English;
    case ApiLocale.HuHu:
      return Locale.Hungarian;
    case ApiLocale.RoRo:
      return Locale.Romanian;
    case ApiLocale.DeDe:
      return Locale.German;
  }
};

export const toApiLocale = (locale: Locale): ApiLocale => {
  switch (locale) {
    case Locale.English:
      return ApiLocale.EnUs;
    case Locale.Hungarian:
      return ApiLocale.HuHu;
    case Locale.Romanian:
      return ApiLocale.RoRo;
    case Locale.German:
      return ApiLocale.DeDe;
  }
};
