import classNames from 'classnames';
import React from 'react';

interface Props {
  className?: string;
}

export const NotificationIndicator: React.FC<Props> = ({ className }) => {
  return <div className={classNames('bg-error h-2 w-2 rounded-full', className)} />;
};
