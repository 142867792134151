import React from 'react';
import { FormattedNumber } from 'react-intl';

interface Props {
  value: number;
  currency?: string;
  minimal?: boolean;
}

export const FormattedCurrency: React.FC<Props> = ({ value, currency, minimal }) => (
  <FormattedNumber {...{ value, currency }} maximumFractionDigits={0} style={minimal ? 'decimal' : 'currency'} />
);
