import React from 'react';

import { AsyncButton } from '../buttons/AsyncButton';
import { Button } from '../buttons/Button';

interface ModalPrimaryButtonProps extends React.ComponentProps<typeof Button> {
  onAction(): void;
}

export const ModalPrimaryButton = React.forwardRef<HTMLElement, React.PropsWithChildren<ModalPrimaryButtonProps>>(
  ({ appearance, onAction, ...props }, ref) => (
    <AsyncButton
      {...{ ref }}
      {...props}
      appearance={appearance ?? 'primary'}
      className="h-10 px-4"
      type="submit"
      onClick={() => onAction()}
    />
  )
);

interface ModalSecondaryButtonProps {
  onClose?(): void;
}

export const ModalSecondaryButton = React.forwardRef<HTMLElement, React.PropsWithChildren<ModalSecondaryButtonProps>>(
  ({ onClose, ...props }, ref) => (
    <Button {...{ ref }} {...props} appearance="secondary" className="h-10 px-4" type="button" onClick={onClose} />
  )
);
