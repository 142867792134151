import classNames from 'classnames';
import React from 'react';

interface Props {
  className?: string;
}

export const Banner: React.FC<React.PropsWithChildren<Props>> = (props) => (
  <div
    {...props}
    className={classNames('bg-warning-light flex items-center justify-between gap-4 rounded-lg p-4', props.className)}
  />
);
