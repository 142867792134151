import { translations } from '@binhatch/locale';
import { PageHeading, Tab, TabList } from '@binhatch/ui';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Outlet } from 'react-router';

import { urls } from '@/utils/url';

export const SettingsPage: React.FC = () => (
  <main className="space-y-2 md:space-y-6">
    <PageHeading title={<FormattedMessage id={translations.pages.settings.title} />} />

    <TabList>
      <Tab end to={urls.settings.root}>
        <FormattedMessage id={translations.tabs.settings.general} />
      </Tab>

      <Tab to={urls.settings.notifications}>
        <FormattedMessage id={translations.tabs.settings.notifications} />
      </Tab>
    </TabList>

    <Outlet />
  </main>
);
