import { useRemoteData, useStrictParams } from '@binhatch/hooks';
import { translations } from '@binhatch/locale';
import { DynamicBackButton, Tab, TabList } from '@binhatch/ui';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Outlet } from 'react-router';

import { userApi } from '@/integrations/api';
import { urls } from '@/utils/url';

import { ClientUserItem } from '@/components/ClientUserItem';

export const UserDetailPage: React.FC = () => {
  const { userId } = useStrictParams<{ userId: string }>();

  const user = useRemoteData({ key: 'useUser', userId }, async ({ userId }) => {
    return userApi.listCustomerUsers(undefined, undefined, [userId]).then((r) => r.data.users[0]);
  });

  return (
    <main className="space-y-2 md:space-y-6">
      <div className="flex justify-between gap-4">
        <DynamicBackButton />
      </div>

      <ClientUserItem user={user.data} />

      <TabList>
        <Tab to={urls.users.getOne({ userId }, urls.users.orders)}>
          <FormattedMessage id={translations.tabs.orders} />
        </Tab>

        <Tab to={urls.users.getOne({ userId }, urls.users.transactions)}>
          <FormattedMessage id={translations.tabs.transactions} />
        </Tab>
      </TabList>

      {!!user.data && <Outlet context={user.data} />}
    </main>
  );
};
