import { XMarkIcon } from '@heroicons/react/20/solid';
import React from 'react';

export const RuleGroupItem: React.FC<React.PropsWithChildren<{ name: React.ReactNode; onDelete?(): void }>> = ({
  name,
  children,
  onDelete
}) => (
  <div className="bg-shade-light space-y-2 rounded px-4 py-3">
    <div className="flex items-center justify-between gap-2">
      <div className="font-semibold">{name}</div>

      {!!onDelete && (
        <button
          className="hover:bg-shade inline-flex h-8 w-8 items-center justify-center rounded"
          type="button"
          onClick={() => onDelete?.()}
        >
          <XMarkIcon className="h-5 w-5" />
        </button>
      )}
    </div>

    {children}
  </div>
);
