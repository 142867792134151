import React from 'react';
import { useLocation } from 'react-router';
import { createContainer } from 'unstated-next';

const useSideNavigation = () => {
  const location = useLocation();

  const [isOpen, setIsOpen] = React.useState(false);

  const toggle = React.useCallback(() => setIsOpen((isOpen) => !isOpen), [setIsOpen]);

  React.useEffect(() => setIsOpen(false), [location, setIsOpen]);

  return { isOpen, toggle };
};

export const SideNavigation = createContainer(useSideNavigation);
