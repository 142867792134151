import React from 'react';
import { FormattedDateTimeRange } from 'react-intl';

interface Props {
  startAt: Date;
  endAt: Date;
}

export const PromotionPeriod: React.FC<Props> = ({ startAt, endAt }) => (
  <FormattedDateTimeRange day="numeric" month="long" from={startAt} to={endAt} />
);
