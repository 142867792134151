import { QueryParamConfig, decodeSingleQueryParam, useQueryParams, useRemoteData } from '@binhatch/hooks';
import { Pagination, TransactionList } from '@binhatch/ui';
import { User } from 'flexinet-api';
import React from 'react';
import { useOutletContext } from 'react-router-dom';

import { transactionApi } from '@/integrations/api';
import { transactionUrlMapper } from '@/utils/transactionMapper';

const page: QueryParamConfig<string | undefined> = {
  decode: (value) => decodeSingleQueryParam(value, undefined),
  encode: (value) => value
};

const config = { page };

export const UserTransactionListPage: React.FC = () => {
  const [query, updateQuery] = useQueryParams({ config });
  const { id: userId } = useOutletContext<User>();

  const transactions = useRemoteData(
    { key: `useTransactions`, userId, page: query.page },
    async ({ page: nextToken }) => transactionApi.listUserTransactions(nextToken, [userId]).then((r) => r.data)
  );

  return (
    <div className="space-y-6">
      <TransactionList
        loading={transactions.isLoading || transactions.isValidating}
        transactions={transactions.data?.transactions}
        urlMapper={transactionUrlMapper}
      />

      <Pagination
        hasNext={!!transactions.data?.nextToken}
        // hasPrevious={!!tales.data?.previousToken}
        onNext={() => updateQuery({ page: transactions.data?.nextToken })}
        // onPrevious={() => updateQuery({ page: tales.data?.previousToken })}
      />
    </div>
  );
};
