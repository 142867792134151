import { translations } from '@binhatch/locale';
import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';

interface Props {
  count: number;
  className?: string;
}

export const DayCount: React.FC<Props> = ({ count, className }) => (
  <div className={classNames('bg-shade inline-flex rounded-md px-2 py-1', className)}>
    <FormattedMessage id={translations.components.days} values={{ count }} />
  </div>
);
