import { useRemoteData } from '@binhatch/hooks';
import { getAllFromApi } from '@binhatch/utility';
import { Product, ProductAvailability, ProductKind } from 'flexinet-api';
import React from 'react';
import { useLocalStorage, useToggle } from 'react-use';
import { createContainer } from 'unstated-next';

import { productApi } from '@/integrations/api';
import { Auth } from './useAuth';

interface CartItem {
  productId: string;
  quantity: number;
}

interface CartProductItem<P = Product | undefined> extends CartItem {
  product: P;
}

const getKey = (userId?: string) => `cart-${userId ?? 'unknown'}`;

const useCart = () => {
  const { context } = Auth.useContainer();
  const [cache, setCache, removeCache] = useLocalStorage<Record<string, CartItem>>(getKey(context?.user.id), {});
  const [open, toggle] = useToggle(false);

  const productIds = React.useMemo(() => Object.keys(cache ?? []), [cache]);

  const products = useRemoteData(
    { key: 'getProducts', productIds },
    async ({ productIds }) => {
      const products =
        productIds.length > 0
          ? await getAllFromApi(
              (nextToken) =>
                productApi.listUserProducts(ProductKind.Item, nextToken, undefined, undefined, ProductAvailability.Available, productIds).then((r) => r.data),
              (r) => r.data
            )
          : [];

      return productIds.reduce<Record<string, Product | undefined>>((map, productId) => {
        map[productId] = products.find((p) => p.id === productId);
        return map;
      }, {});
    },
    { revalidateOnFocus: false, shouldRetryOnError: false }
  );

  const { validItems, outOfStock } = React.useMemo(() => {
    const cart = Object.entries(cache ?? {}).reduce<Record<string, CartProductItem>>((map, [productId, { quantity }]) => {
      map[productId] = { productId, product: products.data?.[productId], quantity };
      return map;
    }, {});

    const items = Object.values(cart ?? {});

    const validItems = items.filter((item): item is CartProductItem<Product> => !!item.product);
    const outOfStock = items.some(({ product }) => !product);

    return { validItems, outOfStock };
  }, [cache, products]);

  return React.useMemo(() => {
    const update = async (product: Product, quantity: number) => {
      const map = { ...cache, [product.id]: { productId: product.id, quantity } };

      if (quantity <= 0) delete map[product.id];

      setCache(map);
    };

    return {
      loading: products.isLoading || products.isValidating,
      items: validItems,
      count: validItems.reduce((count, { quantity }) => count + quantity, 0) ?? 0,
      total: validItems.reduce((total, { product, quantity }) => total + (product?.value ?? 0) * quantity, 0) ?? 0,
      outOfStock,
      update,
      adjust: async (product: Product, quantity: number) => {
        await update(product, (cache?.[product.id]?.quantity ?? 0) + quantity);
      },
      clear: async () => removeCache(),
      open,
      toggle
    };
  }, [cache, setCache, removeCache, products, validItems, outOfStock, open, toggle]);
};

export const Cart = createContainer(useCart);
