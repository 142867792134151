import { Language, fromApiLocale, getLocale, toApiLocale } from '@binhatch/locale';
import flat from 'flat';
import React, { StrictMode } from 'react';
import { Root, createRoot } from 'react-dom/client';
import { IntlProvider } from 'react-intl';
import { BrowserRouter } from 'react-router-dom';
import 'tailwindcss/tailwind.css';

import { notificationApi } from '@/integrations/api';

import { Auth } from '@/containers/useAuth';
import { Configuration } from '@/containers/useConfiguration';
import { Tenant } from '@/containers/useTenant';

import { AnalyticsTracker } from '@/components/AnalyticsTracker';

import { LoadingPage } from '@/pages/LoadingPage';

import { App } from './App';

const root = createRoot(document.getElementById('app')!);

const AppWithTenant: React.FC<React.PropsWithChildren> = ({ children }) => {
  const tenant = Tenant.useContainer();

  if (!tenant.data) return <LoadingPage />;

  return (
    <Auth.Provider>
      <Language.Provider
        initialState={{
          locale: getLocale(fromApiLocale(tenant.data.locale)),
          onChange: (locale) => notificationApi.updatePreferences({ preferredLocale: toApiLocale(locale) })
        }}
      >
        <AppWithLanguage>{children}</AppWithLanguage>
      </Language.Provider>
    </Auth.Provider>
  );
};

const AppWithLanguage: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [locale, , messages] = Language.useContainer();

  if (!messages.data) return <LoadingPage />;

  return (
    <IntlProvider defaultLocale={locale} defaultRichTextElements={{ br: (<br />) as any }} messages={flat(messages.data)} {...{ locale }}>
      <Configuration.Provider>
        <AppWithConfig>{children}</AppWithConfig>
      </Configuration.Provider>
    </IntlProvider>
  );
};

const AppWithConfig: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [{ loading }] = Configuration.useContainer();

  if (loading) return <LoadingPage />;

  return <React.Fragment>{children}</React.Fragment>;
};

function bootstrapApplication(element: Root) {
  element.render(
    <StrictMode>
      <BrowserRouter>
        <Tenant.Provider>
          <AppWithTenant>
            <AnalyticsTracker />
            <App />
          </AppWithTenant>
        </Tenant.Provider>
      </BrowserRouter>
    </StrictMode>
  );
}

bootstrapApplication(root);
