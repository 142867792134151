import { translations } from '@binhatch/locale';
import { CheckBadgeIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';

interface Props {
  className?: string;
}

export const PromotionClaimed: React.FC<Props> = ({ className }) => (
  <div className={classNames('text-success flex items-center gap-2', className)}>
    <CheckBadgeIcon className="h-6 w-6" />
    <FormattedMessage id={translations.components.promotionClaimed.message} />
  </div>
);
