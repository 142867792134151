import { translations } from '@binhatch/locale';
import { TargetMu } from 'flexinet-api';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { FormattedCurrency } from '../FormattedCurrency';

export const TargetValue: React.FC<{ unit: TargetMu; value: number; currency?: string; minimal?: boolean }> = ({
  unit,
  value,
  currency,
  minimal
}) => {
  switch (unit) {
    case TargetMu.Currency:
      return <FormattedCurrency {...{ value, currency, minimal }} />;
    case TargetMu.AdditionalMu:
    case TargetMu.Product:
      return minimal ? (
        <React.Fragment>{value}</React.Fragment>
      ) : (
        <FormattedMessage id={translations.components.targetValue[unit]} values={{ count: value }} />
      );
  }

  throw new Error('Unknown target unit.');
};
