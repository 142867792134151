import { useRemoteData } from '@binhatch/hooks';
import { updateApiAccessToken } from '@binhatch/utility';
import { UserManager } from 'oidc-client-ts';
import { AuthProvider, useAuth as useOidc } from 'oidc-react';
import React from 'react';
import { ContainerProviderProps, createContainer } from 'unstated-next';

import { userApi } from '@/integrations/api';

import { Tenant } from '../useTenant';
import { UseAuthResult } from './types';

const useAuth = (): UseAuthResult => {
  const oidc = useOidc();

  const user = useRemoteData({ key: 'useUser', oidc }, async () => {
    await oidc.userManager.signinSilentCallback();
    return await oidc.userManager.getUser();
  });

  const initializing = oidc.isLoading || user.isLoading;
  const accessToken = user.data?.access_token;
  const authenticated = !!accessToken;

  const login = React.useCallback(() => oidc.signIn(), [oidc]);

  const logout = React.useCallback(() => oidc.signOutRedirect(), [oidc]);

  if (!initializing) {
    updateApiAccessToken(accessToken, async () => {
      const user = await oidc.userManager.signinSilent();

      if (user) return user.access_token;

      await logout().catch(() => void 0);

      throw new Error('Failed to rewnew access token. Signing out.');
    });
  }

  const profile = useRemoteData({ key: 'getProfile', skip: initializing || !authenticated, accessToken }, () => {
    return userApi.getCurrentUser().then((r) => r.data);
  });

  const loading = profile.isLoading || initializing;

  const context = React.useMemo(() => {
    if (!profile.data) return;

    return {
      user: profile.data.user,
      client: profile.data.client
    };
  }, [profile]);

  return React.useMemo(
    () => ({ authenticated, loading, context, login, logout }),
    [loading, authenticated, context, login, logout]
  );
};

const Container = createContainer(useAuth);

const Provider: React.FC<ContainerProviderProps> = (props) => {
  const tenant = Tenant.useContainer();

  const userManager = React.useMemo(() => {
    if (!tenant.data) return;

    const uri = `${window.location.origin}/auth/callback`;

    const manager = new UserManager({
      authority: tenant.data.customerOpenIDIssuer,
      client_id: tenant.data.customerOpenIDClientID,
      extraQueryParams: tenant.data.customerOpenIDParameters,
      metadataUrl: tenant.data.customerOpenIDMetadataURL,
      redirect_uri: uri,
      popup_redirect_uri: uri,
      silent_redirect_uri: uri,
      post_logout_redirect_uri: uri,
      popup_post_logout_redirect_uri: uri,
      scope: `openid profile`,
      loadUserInfo: false,
      automaticSilentRenew: true
    });

    return manager;
  }, [tenant]);

  return (
    <AuthProvider {...{ userManager }} autoSignIn={false} automaticSilentRenew>
      <Container.Provider {...props} />
    </AuthProvider>
  );
};

export const Auth = {
  useContainer: Container.useContainer,
  Provider
};
