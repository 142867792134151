import { Tag } from '@binhatch/ui';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { PencilSquareIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import React from 'react';

export const RuleItem: React.FC<
  React.PropsWithChildren<{
    operator: React.ReactNode;
    negation: boolean;
    tags: string[];
    onUpdate?(): void;
    onDelete?(): void;
  }>
> = ({ operator, negation, tags, onUpdate, onDelete }) => (
  <div
    className={classNames(
      'group flex flex-col gap-1 rounded border bg-white p-2',
      !negation ? 'border-success' : 'border-error'
    )}
  >
    <div className="flex items-center justify-between gap-2">
      <div className="text-sm font-semibold capitalize">{operator}</div>

      <div>
        {!!onUpdate && (
          <button
            className="hover:bg-shade inline-flex h-8 w-8 items-center justify-center rounded opacity-0 group-hover:opacity-100"
            type="button"
            onClick={onUpdate}
          >
            <PencilSquareIcon className="h-5 w-5" />
          </button>
        )}

        {!!onDelete && (
          <button
            className="hover:bg-shade inline-flex h-8 w-8 items-center justify-center rounded opacity-0 group-hover:opacity-100"
            type="button"
            onClick={onDelete}
          >
            <XMarkIcon className="h-5 w-5" />
          </button>
        )}
      </div>
    </div>

    <div>
      <ul className="flex max-w-96 flex-wrap gap-2">
        {tags.map((tag) => (
          <li key={tag}>
            <Tag>{tag}</Tag>
          </li>
        ))}
      </ul>
    </div>
  </div>
);
