import { translations } from '@binhatch/locale';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export const RuleGroupSeparator: React.FC = () => (
  <div className="bg-shade-light relative h-0.5">
    <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 bg-white p-1 text-sm font-semibold uppercase">
      <FormattedMessage id={translations.components.ruleGroupSeparator.or} />
    </div>
  </div>
);
