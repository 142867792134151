import classNames from 'classnames';
import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

interface TabListProps {
  className?: string;
}

export const TabList: React.FC<React.PropsWithChildren<TabListProps>> = ({ className, ...props }) => (
  <div
    {...props}
    className={classNames(
      'bg-shade-light border-shade-light isolate flex flex-col gap-2 whitespace-nowrap rounded border-4 md:flex-row',
      className
    )}
  />
);

interface TabProps {
  to: string;
  end?: boolean;
  className?: string;
}

export const Tab: React.FC<React.PropsWithChildren<TabProps>> = ({ className, ...props }) => {
  const { state } = useLocation();

  return (
    <NavLink
      {...props}
      {...{ state }}
      replace
      className={({ isActive }) =>
        classNames(
          '-ml-px inline-flex h-10 cursor-pointer items-center justify-center rounded px-2 text-sm font-semibold focus:z-10 group-first:mx-0',
          isActive ? 'border-brand bg-brand text-white' : 'border-border hover:bg-shade'
        )
      }
    />
  );
};
