import * as React from 'react';

interface Props extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'value' | 'accept' | 'onChange'> {
  invalid?: boolean;
  multiple?: boolean;
  onChange(files: File[]): void;
  accept: string;
}

export const FileInput = React.forwardRef(
  ({ invalid, className, children, accept, onChange: onChangeCallback, ...props }: Props, ref: React.Ref<any>) => {
    const onChange = React.useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => onChangeCallback(Array.from(event.target.files ?? [])),
      [onChangeCallback]
    );

    return (
      <label htmlFor={props.id} {...{ className }}>
        {children}

        <input {...props} {...{ accept, ref, onChange }} className="m-0 block h-0 w-0 opacity-0" type="file" value="" />
      </label>
    );
  }
);
