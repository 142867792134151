import { translations } from '@binhatch/locale';
import { CoinValue, EmptyState, LoadingState, ProductImage } from '@binhatch/ui';
import { getUserName } from '@binhatch/utility';
import React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { OrderItem } from './OrderItem';
import { OrderStatusTag } from './OrderStatusTag';

interface Props {
  loading: boolean;
  orders: OrderItem[];
}

export const OrderList: React.FC<Props> = ({ loading, orders }) => (
  <LoadingState loading={loading}>
    {!loading && !orders.length && (
      <EmptyState>
        <FormattedMessage id={translations.components.orderList.empty} />
      </EmptyState>
    )}

    <ul className="flex flex-col gap-4">
      {orders.map(({ order, user, client, url }, index) => (
        <li key={index}>
          <Link
            className="flex flex-col justify-between gap-4 rounded bg-white p-8 shadow lg:flex-row"
            state={{ from: 1 }}
            to={url}
          >
            <div className="flex flex-col gap-2">
              <div className="flex-row items-center gap-4 xl:flex">
                <div className="font-semibold">
                  <FormattedMessage id={translations.components.orderList.name} values={{ id: order.id }} />
                </div>

                <OrderStatusTag status={order.status} />
              </div>

              <ul className="grid gap-2">
                {order.items.map((item, index) => (
                  <li className="flex items-center gap-4" key={index}>
                    <ProductImage className="w-10" square src={item.product.media[0]} />
                    {item.qty} x {item.product.name}
                  </li>
                ))}
              </ul>
            </div>

            <div className="flex flex-col items-end">
              <div className="flex items-center gap-2">
                <FormattedMessage
                  id={translations.components.orderList.fromBalance}
                  values={{ type: order.balance?.beneficiary.kind }}
                />

                <CoinValue value={order.items.reduce((total, item) => total + item.product.value * item.qty, 0)} />
              </div>

              <div className="flex items-center gap-2">
                <div>{!!user && (getUserName(user) || user.details.email)}</div>

                <div>|</div>

                <div>{client?.name}</div>
              </div>

              <div>
                <FormattedDate dateStyle="long" timeStyle="short" value={order.createdAt} />
              </div>
            </div>
          </Link>
        </li>
      ))}
    </ul>
  </LoadingState>
);
