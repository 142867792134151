import { diceCoefficient } from 'dice-coefficient';

const normalizeIndex = (index: number) => (index < 0 ? Infinity : index);

export interface FuzzySearchOptions<T> {
  term: string;
  items: T[];
  threshold: number;
  getSearchableAttributes(item: T): string[];
}

export const fuzzySearch = <T>({ term, items, threshold, getSearchableAttributes }: FuzzySearchOptions<T>) => {
  term = term.toLowerCase();

  return items
    .map((item) => {
      const attributes = getSearchableAttributes(item).map((attribute) => attribute.toLowerCase());

      const exactMatches = attributes.map((attribute) => attribute.indexOf(term)).filter((index) => index >= 0);

      const exact = exactMatches.length > 0 ? Math.min(...exactMatches) : -1;
      const similarity = Math.max(...attributes.map((attribute) => diceCoefficient(term, attribute)));

      return { item, exact, similarity };
    })
    .filter((item) => item.exact >= 0 || item.similarity >= threshold)
    .sort((one, two) => {
      const indexDifference = normalizeIndex(one.exact) - normalizeIndex(two.exact);

      // prioritize exact searches first
      if (indexDifference !== 0) return indexDifference;

      return two.similarity - one.similarity;
    })
    .map(({ item }) => item);
};
