import { useRemoteData, useStrictParams } from '@binhatch/hooks';
import { translations } from '@binhatch/locale';
import { DynamicBackButton, OrderDetail, PageLoaderHeading } from '@binhatch/ui';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { orderApi, userApi } from '@/integrations/api';
import { urls } from '@/utils/url';

export const OrderDetailPage: React.FC = () => {
  const { orderId } = useStrictParams<{ orderId: string }>();

  const order = useRemoteData({ key: `useOrder`, orderId, skip: !orderId }, async ({ orderId }) => {
    if (!orderId) throw new Error('Order id not specified.');

    return orderApi.getUserOrder(orderId).then((r) => r.data);
  });

  const userId = order.data?.userID;

  const user = useRemoteData({ key: `useOrderUser`, userId, skip: !userId }, async ({ userId }) => {
    if (!userId) return;
    return userApi
      .listCustomerUsers(undefined, undefined, [userId])
      .then((r) => ({ user: r.data.users[0], url: urls.users.getOne({ userId }) }));
  });

  return (
    <main className="space-y-6">
      <div>
        <DynamicBackButton />

        <PageLoaderHeading className="flex-1" loading={order.isLoading || order.isValidating}>
          <FormattedMessage id={translations.components.orderDetail.title} />
          {order.data?.id ? ` #${order.data?.id}` : ''}
        </PageLoaderHeading>
      </div>

      <OrderDetail
        getProductUrl={(productId) => urls.shop.products.getOne({ productId })}
        loading={order.isLoading || order.isValidating}
        order={order.data}
        user={user.data}
      />
    </main>
  );
};
