import classNames from 'classnames';
import React from 'react';

import { Title } from './Title';

interface Props {
  title: React.ReactNode;
  className?: string;
}

export const PageHeading: React.FC<React.PropsWithChildren<Props>> = ({ title, className, children }) => (
  <header className={classNames('flex h-10 items-center gap-4', className)}>
    <Title as="h1" size="h1">
      {title}
    </Title>

    {children}
  </header>
);
