import classNames from 'classnames';
import React from 'react';

interface Props {
  className?: string;
}

export const Coin: React.FC<React.PropsWithChildren<Props>> = ({ className, children }) => (
  <div className={classNames('flex items-center', className)}>
    <svg className="mr-2 h-6 w-6" fill="none" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <g>
        <circle cx="10" cy="10" fill="#E6883C" r="10" />
        <circle cx="10" cy="10" fill="#FFDB1E" r="9" />
        <path d="M14 7.07432H9.53663V9.14189H13.3069V11.2162H9.53663V15H7V5H14V7.07432Z" fill="#E6883C" />
      </g>
    </svg>

    {children}
  </div>
);
