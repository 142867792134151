import { UserIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import React from 'react';

interface Props {
  className?: string;
}

export const UserAvatar: React.FC<Props> = ({ className }) => (
  <div className={classNames('bg-shade flex h-12 w-12 items-center justify-center rounded', className)}>
    <UserIcon className="h-5 w-5" />
  </div>
);
